.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
  -moz-transition-delay: @transition-delay;
  -o-transition-delay: @transition-delay;
  transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
  -webkit-transition-duration: @transition-duration;
  -moz-transition-duration: @transition-duration;
  -o-transition-duration: @transition-duration;
  transition-duration: @transition-duration;
}
.transform(@transform) {
  -webkit-transform: @transform;
  -moz-transform: @transform;
  -o-transform: @transform;
  transform: @transform;
}
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}