@media (max-width: 991px) {
  .btn-navbar.navbar-toggle {
    position: absolute;
    padding: 0;
    margin: 0;
    left: auto;
    right: 20px;
    display: block;
    i {
      color: @white;
      font-size: 16px;
    }
  }

  .tz-search {
    position: absolute;
    left: auto;
    float: none !important;
    right: 60px;
    i {
      color: @white;
      padding: 0 !important;
    }
  }

  .home-4.services,
  .tz-university-one,
  .custom.image_event {
    margin: 0;
  }

  .style-home-1 {
    height: auto;
    top: 0;
  }

  .menu-home-1 {
    display: none;
  }

  .canvas-menu {
    left: auto !important;
    right: -20em;
  }

  #plazart-mainnav {
    .collapsing {
      .transition-duration(0s);
    }
    .navbar-collapse.collapse {
      width: 100% !important;
      height: auto !important;

      .plazart-megamenu {
        .transition(none);
        height: 100% !important;
        background: #334878;
        left: 0;
        position: fixed;
        top: 55px;
        width: 100%;
        z-index: 9999;
        overflow: auto !important;
        padding-bottom: 20px;
        .level0 {
          width: 100%;
          > li {
            width: 100%;
            .transition(none);
            > a {
              width: 100%;
              border: none !important;
              color: @white;
            }
            &:hover, &.open {
              > a {
                background: #334878 !important;
                color: @white !important;
              }
            }
          }
        }
        .style2 .mega-inner {
          padding: 0;
        }
        .dropdown-menu {
          position: relative !important;
          top: 0 !important;
          width: 100% !important;
          .transition(none);
        }
      }
      .plazart-megamenu {
        &.always-show {
          .dropdown-menu {
            visibility: visible;
            .transition(none);
          }
        }
      }
    }
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .tz-header-4 {
    #plazart-mainnav {
      .nav-collapse, .tz-search {
        display: none !important;
      }
    }
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .tz-menu-canvas {
    .btn-navbar.navbar-toggle {
      display: block;
      position: fixed;
      left: 25px;
      top: 30px;
      margin: 0;
      padding: 0;
      color: @white;
      border: none;
      right: auto;
    }
    .tz-search {
      display: none;
    }
  }

  .tz-menu-canvas {
    position: fixed;
    top: 0;
    width: auto;
    left: 0;
    z-index: 999999;
    > .container {
      width: auto;
      padding: 0;
    }
  }

  .off-canvas {
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .off-canvas body {
    width: 100%;
    overflow-x: hidden;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .off-canvas body > * {
    left: 0;
    .transform(translateX(0));
    .transition(-webkit-transform 500ms ease);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .off-canvas #plazart-mainnav .nav-collapse,
  .off-canvas #plazart-mainnav .nav-collapse {
    display: none;
  }

  .off-canvas #off-canvas-nav {
    display: block;
    position: fixed !important;
    top: 0 !important;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: rgba(255, 255, 255, 0.5) !important;
    z-index: 9999999 !important;
  }

  .off-canvas #off-canvas-nav .plazart-mainnav {
    margin: 0;
    position: fixed;
    left: 0;
    top: 0 !important;
    width: 250px;
    .transform(translateX(-100%));
    height: 100%;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .off-canvas #off-canvas-nav .plazart-mainnav .nav-collapse {
    height: auto;
    background: none;
    display: block;
  }

  .off-canvas-enabled body > * {
    .transform(translateX(250px));
  }

  .off-canvas-enabled #plazart-mainnav {
    display: block;
  }

  #off-canvas-nav {
    .plazart-mainnav {
      .nav-collapse {
        padding: 0;
        margin: 0;
        display: block !important;
        border: none;
      }
      .plazart-megamenu {
        &:after {
          display: block;
          content: '';
          clear: both;
        }
        .level0 {
          width: 100%;
          background: #333333;
          margin: 0;
          > li {
            float: none;
            width: 100%;
            &:after {
              display: block;
              content: '';
              clear: both;
            }
            > a {
              text-transform: uppercase;
              .caret {
                display: none;
              }
            }
            .dropdown-menu {
              visibility: visible;
              top: 0;
              width: 100%;
              position: relative;
              padding: 0;
              margin: 0;
              border: none;
              background: transparent;
              .box-shadow(none);
              .mega-inner {
                padding: 10px 0;
              }
              ul {
                li {
                  a {
                    padding: 5px 15px;
                  }
                }
              }
            }
          }
        }
        ul {
          li {
            &.open,
            &.active,
            &.current {
              > a {
                background: @white;
                color: #333333;
              }
            }
            a {
              color: @white;
              &:hover {
                background: #000000;
                color: @white;
              }
            }
          }
        }
      }
    }
  }

  .tz-header-6 {
    .btn-navbar.navbar-toggle {
      position: absolute;
      right: 10px;
      top: 40px;
      left: auto;
      i {
        color: @color-4;
      }
    }
    #plazart-mainnav {
      .tz-search {
        padding: 0;
        margin: 0;
        border: none;
        font-size: 16px;
        top: 38px;
        right: 55px;
        left: auto;
        float: none;
        position: absolute;
      }
    }
    &.tz-header-6-eff {
      #tzlogo {
        position: relative;
        left: 0;
      }
    }
  }

  .tz-header-7 {
    .btn-navbar.navbar-toggle {
      .transition(all 0.3s linear 0s);
      left: auto;
      right: 20px;
      top: 13px;
      i {
        font-size: 16px;
      }
    }
    #plazart-mainnav.navbar {
      min-height: 0;
    }
    #tzlogo {
      padding: 0 0 0 30px;
      .transition(all 0.3s linear 0s);
    }
    .tz-search {
      padding: 0;
      float: none;
      left: auto;
      right: 70px;
      position: absolute;
      top: 14px;
      i {
        font-size: 16px;
      }
    }
    .theme-header-7 {
      padding: 10px 0;
    }
    &.tz-header-7-eff {
      #tzlogo {
        padding: 0;
      }
      .btn-navbar.navbar-toggle {
        top: 13px;
        right: 0;
      }
      .tz-search {
        right: 50px;
      }
    }
  }

  .tz-header-5 {
    .btn-navbar.navbar-toggle {
      left: auto;
      right: 30px;
      top: 40px;
    }
    .tz-search {
      position: absolute;
      right: 75px;
      top: 40px;
      margin: 0;
      padding: 0;
    }
    &.tz-header-5-eff {
      .tz-search {
        margin: 0;
        top: 30px;
      }
      .btn-navbar.navbar-toggle {
        top: 30px;
      }
    }
  }

  .tz-header-home-1 {
    position: fixed;
    top: 0;
    width: 100%;
    background: #334878;
    height: auto;
    z-index: 9999;
    padding: 10px 0;
    .menu {
      top: 0;
    }
    .btn-navbar.navbar-toggle {
      right: 30px;
      left: auto;
      top: 15px;
    }
    .tz-search {
      position: absolute;
      bottom: auto;
      right: 80px;
      top: 15px;
      margin: 0;
      padding: 0;
      i {
        padding: 0;
      }
    }
    &.affix-eff {
      padding: 5px 0;
      .btn-navbar.navbar-toggle {
        top: 21px;
      }
      .tz-search {
        top: 20px;
      }
    }
  }

  .tz-header-3 {
    top: 0;
    background: #2d938e;
    #tzlogo img {
      max-width: 100%;
      width: auto;
    }
    #plazart-mainnav .tz-search {
      padding: 0;
      right: 40px;
      top: 45px;
      margin: 0;
      .transition(all 0.3s linear 0s);
    }
    .btn-navbar.navbar-toggle {
      left: auto;
      top: 45px;
      right: 0;
      .transition(all 0.3s linear 0s);
    }
    &.tz-header-3-eff {
      position: fixed;
      top: 0;
      #tzlogo img {
        position: relative;
        top: 0;
      }
      .btn-navbar.navbar-toggle {
        top: 40px;
        right: 0;
      }
      #plazart-mainnav .tz-search {
        padding: 0;
        right: 40px;
        margin: 0;
        top: 40px;
      }
    }
  }

  .event-contents .content {
    margin: 0 -30px;
  }

  .tz-header-1 {
    .btn-navbar.navbar-toggle, .tz-search {
      top: 15px;
    }
    #tzlogo {
      border: none;
    }
  }

  aside#tz-right {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tz-social-login {
    .joms-input--append {
      padding: 10px 0;
    }
    .tz-input-pass, .tz-input-name {
      width: 80%;
    }
  }

  .joms-single-group,
  .joms-single-member {
    .joms-focus__header {
      .joms-focus__title {
        margin: 0;
      }
    }
    .joms-focus__link {
      li {
        a {
          font-size: 11px;
        }
      }
    }
  }

  .joms-focus__header__actions {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 50px;
    margin: 0;
  }

  .tz-about-school {
    padding-bottom: 20px;
    h4, p {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .login .form-horizontal {
    .button-login {
      text-align: left;
    }
    .other-links ul {
      li {
        display: inline-block;
        margin-right: 10px;
        .checkbox {
          input {
            position: relative;
          }
        }
      }
    }

  }
  .contact #contact-form .form-group label {
    padding-bottom: 10px;
  }

}