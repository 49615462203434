// Animated Icons
// --------------------------

.@{fa-css-prefix}-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

.@{fa-css-prefix}-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}




@-o-keyframes rotate-icon{
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform:rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes scale-icon{
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes rotate-icon{
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform:rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes scale-icon{
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes rotate-icon{
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform:rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes scale-icon{
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes rotate-icon{
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform:rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes scale-icon{
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes rotate-icon{
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform:rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes scale-icon{
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}