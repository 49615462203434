@import "../../import/default/1199";
@import "../../import/default/1199x992";
@import "../../import/default/991";
@import "../../import/default/991x768";
@media (max-width: 768px) {
  .TzStatistic .video-content .tz-video-content .tz h3 {
    font-size: 30px;
    line-height: 30px;
  }

  .tz_gird .courses_portfolio, .tz_gird .seminarmancoursetable {
    margin: 0;
  }

  .tz_gird .courses_portfolio .TzInner, .tz_gird .seminarmancoursetable .TzInner {
    margin: 0;
  }

  .tz_gird .tz-description, .tz_gird .osg_info,
  .tz_gird .tz-img, .tz_gird .osg_image {
    width: 100% !important;
  }

  .seminarmancoursetable .TzInner.osg_left .osg_info, .seminarmancoursetable .TzInner.osg_left .osg_image,
  .seminarmancoursetable .TzInner.osg_right .osg_info, .seminarmancoursetable .TzInner.osg_right .osg_image {
    float: none;
  }

  .seminarmancoursetable .TzInner {
    &:hover {
      .osg_info {
        opacity: 1;
      }
    }
    .osg_info {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      .transition(all 0.3s linear 0s);
      padding: 30px;
    }
  }
}
@import "../../import/default/480";

