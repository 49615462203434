@media (max-width: 480px) {
  .tz-mod-ethic .module-title {
    padding-bottom: 15px;
  }

  .home-style-2 {
    #tz-services-wrapper {
      padding: 30px 0 !important;
    }
    #tz-mail-custom-wrapper.tz-mail {
      padding: 30px 0 !important;
    }
    div.acymailing_module .acymailing_module_form {
      margin-top: 0 !important;
      .acymailing_introtext {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
      }
    }
  }

  div.acymailing_module .acysubbuttons::before {
    padding-right: 8px;
  }

  .tz-header-4 {
    height: 65px;
    .transition(all 0.3s linear 0s);
    .logo #tzlogo img {
      width: 60px !important;
      margin-left: -30px !important;
    }
    .tz-search {
      top: 25px !important;
      margin-right: 40px;
      i {
        font-size: 18px;
      }
    }
    &.tz-header-4-eff {
      .tz-search {
        margin-right: 30px;
      }
      .tz-button-toggle.tz-canvas-customer {
        margin: 0;
      }
    }
  }

  .tz-header-5 {
    #tzlogo img {
      padding: 15px;
    }
    #plazart-mainnav .tz-search {
      top: 25px;
      right: 50px;
    }
    .btn-navbar.navbar-toggle {
      right: 15px;
      top: 25px;
    }
    &.tz-header-5-eff {
      #tzlogo img {
        padding: 15px;
      }
      #plazart-mainnav .tz-search {
        right: 30px;
      }
      .btn-navbar.navbar-toggle {
        right: 0;
        top: 25px;
      }
    }
  }

  .tz-header-6 {
    #tzlogo {
      margin: 0;
    }
    .btn-navbar.navbar-toggle,
    #plazart-mainnav .tz-search {
      top: 18px;
    }
    .btn-navbar.navbar-toggle {
      right: 0;
    }
    #plazart-mainnav .tz-search {
      right: 30px;
    }
  }

  .tz-header-3 {
    #plazart-mainnav .tz-search,
    .btn-navbar.navbar-toggle {
      top: 20px;
    }
    .btn-navbar.navbar-toggle {
      right: 0;
    }
    //    #plazart-mainnav .tz-search {
    //      right: 20px;
    //    }
    &.tz-header-3-eff {
      #plazart-mainnav .tz-search,
      .btn-navbar.navbar-toggle {
        top: 20px;
      }
    }
  }

  .tz-header-home-1 {
    .btn-navbar.navbar-toggle {
      right: 0;
    }
    .tz-search {
      right: 50px;
    }
  }

  .tz-header-2 {
    #tzlogo {
      padding: 15px 0;
      img {
        max-width: 100%;
      }
    }
    .tz-search,
    .btn-navbar.navbar-toggle {
      top: 20px;
    }
    .tz-search {
      display: block;
      right: 50px;
    }
    .btn-navbar.navbar-toggle {
      left: auto;
      right: 0;
      position: absolute;
    }
    &.tz-header-2-eff {
      .tz-search,
      .btn-navbar.navbar-toggle {
        top: 15px;
      }
      #tzlogo {
        padding: 10px 0;
      }
    }
  }

  .tz-header-1 {
    .btn-navbar.navbar-toggle {
      right: 0;
    }
    .tz-search {
      right: 50px;
    }
  }

  #tzlogo {
    img {
      max-width: 100% !important;
    }
  }

  .tz-button-toggle.tz-canvas-customer {
    top: 25px;
    margin: 0;
    position: fixed;
    right: 24px;
    left: auto;
    i {
      font-size: 18px;
    }
  }

  div.acymailing_module {
    .acymailing_module_form {
      padding: 20px 0 30px !important;
      margin-top: 0px !important;
      .acymailing_introtext {
        margin: 0 0 15px 0 !important;
        text-align: center;
      }
      .acymailing_introtext,
      .acymailing_form {
        float: none !important;
        width: auto !important;
        display: block;
      }
      .acymailing_form {
        position: relative;
        padding: 0 !important;
        .acyfield_email, .acyfield_name {
          display: block;
          margin: 0 0 10px 0 !important;
          input {
            min-width: 0;
            padding: 5px 10px;
          }
        }
      }
      .acysubbuttons {
        position: relative;
        right: auto;
        display: block;
        padding-top: 20px !important;
        input {
          padding: 5px 10px 5px 30px;
        }
        &:after {
          left: 50%;
          margin-left: -50px;
          top: 28px;
        }
      }
    }
  }

  .btn-navbar.navbar-toggle {
    top: 25px;
    i {
      font-size: 18px;
    }
  }

  .tz-content-right {
    margin: 0 -15px;
  }

  #tz-sidemenu {
    margin: 0 -15px;
    li a {
      padding: 10px 20px;
      i.tz-icon-2 {
        left: 20px;
        top: 15px;
      }
    }
  }

  .tz-tab-description {
    padding: 18px 15px 15px;
    span a {
      padding: 5px;
      margin: 0 8px 8px 0;
    }
    p {
      font-size: 13px;
    }
  }

  .tz-introduce-content {
    h4 {
      font-size: 16px;
      padding: 20px 0;
    }
    p {
      font-size: 12px;
    }
  }

  .parallax-style-4 .tz-overlay h4 {
    font-size: 20px;
    line-height: 30.6px;
  }

  .services {
    margin-bottom: 20px;
    padding: 20px 0 0;
    .service {
      width: 95%;
      margin: auto auto 20px auto;
    }
  }

  #tz-services-wrapper.tz-services {
    padding: 0 !important;
  }

  section {
    &#tz-courses-wrapper {
      padding-bottom: 0 !important;
    }
    &#tz-mail-wrapper {
      padding: 0 !important;
    }
    &#tz-employee-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .services2 {
    padding: 15px 0;
    margin: 0 -15px;
    .custom_text {
      text-align: center;
      p {
        padding: 30px 0 0;
        margin: 0;
      }
      .custom_button {
        margin: 15px 0;
      }
    }
    .service {
      padding: 75px 0;
      height: auto;
      width: 80%;
      margin: auto auto 20px;
    }

  }

  .tz-header-7 {
    .tz-search,
    .btn-navbar.navbar-toggle {
      top: 10px;
    }
    &.tz-header-7-eff {
      .tz-search,
      .btn-navbar.navbar-toggle {
        top: 10px;
      }
    }
  }

  .full-width {
    .tz-portfolio-project,
    .courses_portfolio {
      margin: 0 !important;
    }
  }

  .courses_portfolio {
    margin: 0 -15px !important;
    .portfolio-item {
      .tz-description,
      .tz-img {
        width: 100%;
        float: none !important;
      }
      .tz-description {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        .transition(all 0.3s linear 0s);
        background: rgba(255, 255, 255, 0.5);
      }
      &:hover {
        .tz-description {
          opacity: 1;
        }
      }
    }
  }

  .tz-portfolio-project {
    margin: 0 -15px;
    a {
      padding: 20px 0;
    }
  }

  .services.home-3 {
    margin: 0 -15px;
    > div {
      padding: 0;
    }
    .service {
      width: 100%;
    }
  }

  .tz-map {
    padding: 0;
  }

  .cus_home3 {
    margin: 0 -15px;
  }

  .home-style-3 {
    .mod_events_latest_table.row {
      margin: 0 -15px;
    }
  }

  .tz_gird .tz-description, .tz_gird .osg_info,
  .tz_gird .tz-img, .tz_gird .osg_image {
    width: 100% !important;
  }

  .tz_gird .courses_portfolio .TzInner, .tz_gird .seminarmancoursetable .TzInner {
    margin: 0;
  }

  .tz-introduce-content-style-3 {
    padding: 60px 30px 48px;
    margin: 0 -15px;
  }

  .event-home-5,
  .event-home-4 {
    padding: 0;
  }

  .tz-university-one,
  .custom.image_event,
  .custom-image {
    margin: 0 -15px;
  }

  .tz-introduce-content-style-2 {
    padding-bottom: 15px;
    .tz-introduce-images {
      margin: 0 -15px;
    }
    h3 {
      a {
        font-size: 18px;
      }
    }
    p {
      font-size: 13px;
    }
    > a {
      margin-top: 15px;
      span {
        padding: 5px 10px;
      }
    }
  }

  .style-home-6 .home-4.services {
    margin: 0 -15px;
    .service {
      margin: 0;
      width: 100%;
    }
  }

  .event-contents .content {
    margin: 0 -15px;
  }

  .tz-more-details {
    margin: 15px 0;
    float: none;
  }

  .team-box {
    margin: 0 -15px;
  }

  .tz-header-1 {
    #tzlogo {
      img {
        max-width: 100%;
      }
    }
  }

  .tz-phone-fax {
    position: relative;
    right: 0;
  }

  .tz-menu-canvas .btn-navbar.navbar-toggle {
    top: 25px;
  }

  .cus_home3 {
    .tz-information-content {
      margin: 0 0 20px 0;
      .block1 {
        padding-bottom: 15px;
      }
      > .row {
        margin: 0;
      }
    }
  }

  .tz-training-images {
    margin: 0 -15px;
  }

  .tz-filter-member {
    padding-left: 15px;
  }

  .jomsocial-wrapper .jomsocial .joms-page .joms-list--card .joms-list__item {
    .joms-list__cover {
      width: 100%;
    }
    .joms-list__content {
      padding: 15px 0;
    }
  }

  .joms-list__search {
    .tz-members-title {
      margin: auto;
      padding: 15px;
      font-size: 18px;
    }
  }

  .tz-detail-member .tz-info {
    padding: 0;
  }

  .pagination-list li a {
    padding: 5px 10px !important;
  }

  .tz-filter-member ul.joms-list--alphabet {
    li {
      display: inline-block;
    }
  }

  .home-4.services {
    margin: 0 -15px;
    > div {
      padding: 0;
    }
    .service {
      width: 100%;
      margin: 0;
    }
  }

  .event-home-4 .mod_events_latest_table {
    .mod_events_latest_content a {
      font-size: 14px;
    }
    .mod_events_latest_date {
      font-size: 12px;
    }
  }

  .tz-about-school {
    padding-bottom: 15px;
    margin: 0 -15px 20px;
  }

  .home-4 form#login-form {
    margin: 0 -15px;
    padding: 30px 15px 30px;
  }

  .tz-institution {
    margin: 0 -15px;
    span {
      padding: 30px 15px;
    }
    p {
      font-size: 14px;
      padding: 15px;
    }
  }

  .tz-services-wrapper h3 {
    margin: 20px 0 0 0;
  }

  .tz-item-more-details {
    margin-bottom: 20px;
  }

  .tz-event-wrapper.style-1 {
    .mod_events_latest,
    .mod_events_latest_first {
      padding: 0;
    }
  }

  #jevents_body #jev_maincal .jev_listrow {
    .tz-events-item {
      margin: 0 -15px;
    }
  }

  #jevents_body ul.ev_ul li.ev_td_li {
    .tz-images {
      margin: 0 -15px;
    }
  }

  .TzBlog .TzItemsRow .TzItem {
    padding-top: 0;
  }

  .TzItemsRow {
    > div {
      padding: 0;
      .blog_info {
        padding: 0 15px;
      }
    }
  }

  #TzTimeline {
    .oe-timeline-container {
      margin: 0 -15px;
    }
    .oe-pad-normal {
      padding: 0 15px;
    }
  }

  .oe-blog-item {
    padding: 0 !important;
  }

  .ss-container::before {
    width: 0;
  }

  .pagination > li {
    display: inline-block;
    margin: 0;
  }

  .profile-edit, .profile, .newsfeed-category, .remind, .reset, .login, .registration {
    .page-header {
      p {
        font-size: 13px;
        padding: 0 10px;
      }
      h1 {
        font-size: 18px;
      }
    }
    .form-horizontal {
      padding: 0;
      .btn-login, .btn-reset {
        padding: 10px 15px;
      }
    }
  }

  .joms-single-group,
  .joms-single-member {
    .joms-focus__header {
      position: relative;
    }
    .joms-focus__link {
      padding: 0 15px;
      li {
        width: auto;
        padding: 5px !important;
      }
    }
    .tz_joms_interaction {
      bottom: auto;
      position: relative;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      text-align: center;
      right: 0;
    }
  }

  .joms-body .joms-stream__body {
    width: 72% !important;
    margin-left: 10px;
  }

  .joms-focus__badges {
    top: 25px;
    position: absolute;
    margin-left: -52px;
    left: 50%;
    padding: 0;
  }

  .contact {
    .contact-address > div {
      padding: 20px 15px;
    }
    .contact-miscinfo {
      padding: 0;
    }
  }
}