@media (max-width: 991px) and (min-width: 768px) {
  .tz-mod-ethic .module-title {
    padding-bottom: 25px;
  }

  .tz-header-4 {
    height: 85px;
    .transition(all 0.3s linear 0s);
    .tz-search {
      margin-right: 0px;
      i {
        font-size: 18px;
      }
    }

    &.tz-header-4-eff {
      height: 75px;
      .tz-search,
      .tz-button-toggle.tz-canvas-customer {
        top: 28px;
        margin: 0;
      }
      .btn-navbar.navbar-toggle {
        top: 28px;
      }
    }
  }

  .tz-menu-canvas {
    .btn-navbar.navbar-toggle {
      top: 33px;
      i {
        font-size: 18px;
      }
    }
  }

  .tz-button-toggle.tz-canvas-customer {
    top: 33px;
    margin: 0;
    position: fixed;
    right: 24px;
    left: auto;
    i {
      font-size: 18px;
    }
  }

  div.acymailing_module {
    .acymailing_module_form {
      padding: 60px 20px 30px !important;
      .acymailing_introtext {
        margin: 0 0 15px 0 !important;
        text-align: center;
      }
      .acymailing_introtext,
      .acymailing_form {
        float: none !important;
        width: auto !important;
        display: block;
      }
      .acymailing_form {
        position: relative;
        padding: 0 !important;
        .acyfield_email, .acyfield_name {
          display: block;
          margin: 0 0 10px 0 !important;
        }
      }
      .acysubbuttons {
        position: relative;
        right: auto;
        display: block;
        padding-top: 20px !important;
        top: 0;
        &:after {
          left: 50%;
          margin-left: -60px;
          top: 33px;
        }
      }
    }
  }

  .tz-phone-fax {
    padding: 15px 25px;
    img {
      display: none;
    }
  }

  #tz-sidemenu {
    margin: inherit;
    li a {
      padding: 10px 20px;
      i.tz-icon-2 {
        left: 20px;
        top: 15px;
      }
    }
  }

  .tz-tab-description {
    padding: 23px 20px 20px;
    span a {
      padding: 5px;
      margin: 0 8px 8px 0;
    }
  }

  .services {
    margin-bottom: 20px;
    padding: 20px 0 0;
    .service {
      width: 80%;
      margin: auto auto 20px auto;
    }
  }

  .services2 {
    .custom_text {
      text-align: center;
      padding-bottom: 35px;
    }
    .service {
      width: 90%;
      padding: 80px 0;
      margin: auto auto 20px auto;
      height: auto;
    }
  }

  #tz-services-wrapper.home-style-2 {
    padding: 45px 0 !important;
  }

  .tz-introduce-content.tz-introduce-content-style-7 {
    padding: 30px 15px;
  }

  .home-3.services {
    margin-left: 0;
    margin-right: 0;
    > div {
      padding: 0;
      .service {
        width: 100%;
      }
    }
  }

  .home-style-3 {
    .mod_events_latest_table.row {
      padding-top: 30px;
    }
  }

  .home-4.services {
    > div {
      padding: 0;
      .service {
        margin: 0;
        width: 100%;
      }
    }
  }

  .tz-header-2 {
    .btn-navbar.navbar-toggle {
      left: auto;
      right: 30px;
      top: 50px;
      position: absolute;
    }
    &.tz-header-2-eff {
      .btn-navbar.navbar-toggle {
        top: 30px;
      }
    }
  }

  .event-home-5 ul {
    border-right: none;
  }
}