@media (max-width: 1199px) and (min-width: 992px) {
  .tz-header-4 {
    .menu-home-1 > li > a {
      padding: 34px 22px;
    }
    .tz-button-toggle.tz-canvas-customer {
      margin: 30px 30px 0;
    }
    .tz-search {
      margin: 0 20px 0 30px;
    }
    &.tz-header-4-eff {
      .menu-home-1 > li > a {
        padding: 25px 32px;
      }
      .tz-button-toggle.tz-canvas-customer {
        margin-left: 0;
        margin-right: 0;
      }
      .tz-search {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .tz-header-6 {
    .navbar-inner {
      .plazart-megamenu .level0 > li > a {
        padding: 19px 17px 20px;
      }
    }
  }

  .tz-header-7 {
    .navbar-inner {
      .nav-collapse {
        margin-left: 50px;
      }
    }
    #plazart-mainnav .level0 {
      > li > a {
        padding: 37px 18px;
        border: none;
      }
    }
  }

  div.acymailing_module .acymailing_form .acyfield_name input,
  div.acymailing_module .acymailing_form .acyfield_email input {
    min-width: 200px;
  }

  .tz-phone-fax {
    padding-bottom: 30px;
    a {
      img {
        margin: 20px 0;
        width: 45px;
      }
    }
  }

  .services {
    margin: 10px 0 0 -20px;
    .service {
      padding: 60px 0;
      height: auto;
    }
  }

  .services2 {
    padding: 35px 15px;
    .custom_text {
      text-align: center;
      h3 {
        padding-top: 30px;
        a {
          font-size: 20px;
          line-height: 21.8px;
        }
      }
      p {
        padding: 30px 30px 30px 15px;
        margin: 0;
      }
      .custom_button {
        margin-top: 10px;
        span {
          padding: 5px 10px;
        }
      }
    }
    .service {
      height: auto;
      padding: 60px 0;
    }
  }

  #tz-services-wrapper {
    &.tz-services.home-style-2 {
      padding: 30px 0 !important;
    }
  }

  .tz-training-images > div .tz-training-content {
    h6 {
      margin: 15px 0;
    }
  }

  .tz-header-3 {
    #plazart-mainnav .level0 > li {
      padding: 40px 10px;
      a {
        padding: 10px;
      }
    }
    &.tz-header-3-eff {
      #plazart-mainnav {
        .level0 > li {
          a {
            padding: 10px 20px;
          }
        }
        .tz-search {
          margin-top: 8px;
        }
      }
    }
  }

  .event-home-4 {
    .mod_events_latest_table > div {
      padding-bottom: 20px;
      .tz-training-content {
        .mod_events_latest_content {
          padding-bottom: 10px;
        }
      }
    }
  }

  #map {
    margin-top: 30px;
  }

  .home-4.services {
    .service {
      padding: 45px 0;
    }
  }

  .tz-about-school {
    padding-bottom: 20px;
    h4 {
      font-size: 16px;
    }
    p, h4 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .home-4 form#login-form {
    margin: 0;
    padding: 20px 15px 25px;
    .pretext > div {
      padding: 5px 0 20px;
    }
    .btn-login {
      padding: 10px 20px;
    }
  }

  .tz-header-2 {
    #plazart-mainnav {
      .level0 > li > a {
        padding: 15px 20px;
      }
    }
  }

  .tz-header-5 {
    #plazart-mainnav .level0 > li > a {
      padding: 15px;
    }
    &.tz-header-5-eff {
      #plazart-mainnav .level0 > li > a {
        padding: 15px;
      }
    }
  }

  .contact #contact-form .form-group label {
    padding-bottom: 15px;
  }

  .contact #contact-form .form-group input, .contact #contact-form .form-group textarea {
    margin-bottom: 15px;
  }
}