body {
  font-weight: 400;
}

a:focus {
  outline: none;
  outline-offset: 0;
}

a:hover {
  text-decoration: none;
}

.width_auto {
  width: auto;
  padding: 0;
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.tz-mod-ethic {
  .module-title {
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 20px;
    padding-bottom: 45px;

    text-align: center;
    i {
      font-size: 12px;
      display: block;
      padding-bottom: 15px;
    }
  }
}

//section, footer, header {
//  > .container {
//    //    padding: 0;
//    > .row {
//      margin: 0 -30px;
//    }
//  }
//}

ul {
  list-style: none;
}

.container-fluid {
  .parallax-style-4,
  #home_content_portfolio {
    margin: 0 -15px;
  }
}

@font-face {
  font-family: 'tzethicicon';
  src: url('../../../images/tzethicicon/tzethicicon.eot?-q0guyp');
  src: url('../../../tz_joomla_ethic/images/tzethicicon/tzethicicon.eot?#iefix-q0guyp') format('embedded-opentype'),
  url('../../../images/tzethicicon/tzethicicon.woff?-q0guyp') format('woff'),
  url('../../../images/tzethicicon/tzethicicon.ttf?-q0guyp') format('truetype'),
  url('../../../images/tzethicicon/tzethicicon.svg?-q0guyp#tzethicicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="tz_ethic_"], [class*=" tz_ethic_"] {
  font-family: 'tzethicicon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tz_ethic_cross:before {
  content: "\e117";
}

/*-------------- Pagination ---------------*/
.blog_pagination {
  display: block;
  text-align: center;
  .pagination {
    display: inline-block;
    text-align: inherit;
  }
}

.pagination-list {
  li {
    &:first-child {
      a {
        border-radius: 0;
      }
    }
    &:last-child {
      a {
        border-radius: 0;
      }
    }
    a {
      padding: 12px 20px !important;
      margin: 0 3px;
      min-width: 40px;
      border-color: @color-3;
      color: @color-3;
      background: transparent;
      i {
        font-size: 18px;
      }
    }
    &:hover, &.active {
      a {
        color: @color-4 !important;
        border-color: @color-4 !important;
        background: transparent !important;
      }
    }
    &.disabled {
      a {
        border-color: @color-3;
        color: @color-3;
      }
    }
  }
}

/*--------------- Global Joms --------------*/
.joms-tab__bar {
  a {
    &.active, &:hover, &.active:hover {
      background: @color-1;
      color: @white;
    }
  }
}

.joms-dropdown {
  li {
    a {
      color: @color-1;
      &:hover {
        color: @color-1;
      }
    }
  }
}

/*-------------- Social-Activity -----------*/
.joms-toolbar--desktop .joms-popover {
  top: 100%;
  left: 0;
}

.mfp-bg {
  z-index: 99999999;
}

.mfp-wrap {
  z-index: 99999999;
}

.joms-dropdown {
  z-index: 99999;
}

.sub_menu_joms_group {
  .tz-btn-default {
    color: @color-4 !important;
    padding: 6px 10px;
    &:hover {
      color: @white !important;
    }
  }
}

.tz-social-login {
  background: none repeat scroll 0 0 #f6f6f6;
  padding: 22px 0 22px 20px;
  h3 {
    margin: 0;
    font-size: 14px;
    color: @color-1;
    line-height: 25.2px;
  }
  form {
    padding: 11px 0 0;
    input {
      &.tz-input-name,
      &.tz-input-pass {
        padding: 8px 20px !important;
        border: none;
        margin: 0 10px 0 0;
        border-radius: 0;
      }
    }
    button.joms-button--login {
      width: auto;
      .box-shadow(none);
      .transition(all 0.3s ease 0s);
      border-radius: 0;
      padding: 7px 15px;
      color: @color-4 !important;
      &:hover, &:focus {
        border-color: @color-1 !important;
        background: transparent;
        color: @color-1 !important;
      }
    }
    .joms-input--append {
      vertical-align: inherit;
      margin: 0;
      width: auto;
    }
    .edit_info_acc {
      padding: 0 25px;
      color: @color-2;
      font-size: 13px;
      &:hover {
        color: @color-1;
      }
    }
    .joms-checkbox {
      padding-right: 25px;
    }
  }
  label {
    font-size: 14px;
    color: @color-2;
    line-height: 25.2px;
    font-weight: 400;
    padding: 5px 20px 0 0;
  }
  .tz-input-name {
    width: 180px;
  }
  .tz-input-pass {
    width: 280px;
  }
  span {
    display: inline-block;
    a {
      color: @color-2;
      display: inline-block;
      padding: 0 100px 0 0;
    }
  }
}

.joms-landing {
  margin: 0;
  .joms-landing__cover {
    margin: 22px 0 0;
    position: relative;
    .joms-landing__content {
      display: block;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      .joms-landing__signup {
        display: none;
      }
      .joms-landing__text {
        display: table;
        height: 100%;
        width: 100%;
        .ds-table-cell {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
        h3 {
          margin: 5px 0 2px;
          color: @white;
          font-size: 22px;
          line-height: 26.4px;
          text-transform: uppercase;
        }
        p {
          color: @white;
          font-size: 14px;
          line-height: 22.4px;
        }
      }
    }
  }
}

.joms-body {
  .tz_joms_photos {
    .tz-comment-quote {
      border-left: 3px solid @color-3;
      color: @color-5;
      margin: 32px 0 0;
      padding: 5px 0 5px 20px;
    }
  }
  .tz_joms_friends {
    &.joms-stream__body {
      padding: 18px 0 19px;
      margin-left: 40px;
    }
    i {
      padding-right: 38px;
    }
    a {
      color: @color-1;
      font-weight: 400;
    }
  }
  .joms-list__options {
    a {
      color: @color-1;
      right: 15px;
      top: 25px;
      i {
        font-size: 20px;
      }
    }
    .joms-dropdown {
      right: 15px;
      top: 50px;
    }
  }
  .joms-stream__header {
    padding: 0;
    .joms-avatar--stream {
      width: 70px;
      img {
        border-radius: 0;
        border: none;
        padding: 0;
        max-width: none;
        .transition(all 0.3s ease 0s);
        &:hover {
          border-radius: 50%;
        }
      }
    }
  }
  .joms-stream__body {
    width: 89% !important;
    margin-left: 23px;
    .joms-stream__meta {
      display: block;
      padding: 0;
      .joms-stream__time {
        color: @color-6;
        font-size: 13px;
        line-height: 15.6px;
        padding-top: 12px;
        > * {
          font-size: 13px;
        }
      }
      .joms-stream__user, a {
        color: @color-1;
        font-weight: 400;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .joms-stream__actions {
      padding: 28px 0 0;
      .joms-stream__actions_child, .joms-stream__status {
        background: none repeat scroll 0 0 @color-3;
        margin: 0 0 5px;
        padding: 7px 0;
      }
    }
    .joms-stream__actions_child {
      a {
        padding: 0 5px 0 10px;
        margin: 0;
        color: @color-1;
      }
    }
    .joms-stream__status {
      a {
        font-size: 13px;
        line-height: 1.5em;
        color: @color-1;
      }
      i {
        margin: 0 15px 0 25px;
        color: @color-4;
      }
    }
    .joms-comment {
      .joms-comment__item {
        padding: 0;
        .joms-comment__header {
          padding: 20px;
          .joms-avatar--comment {
            img {
              border-radius: 0;
              padding: 0;
              border: none;
              width: 40px;
            }
          }
          .joms-comment__body {
            padding: 0 20px;
            .joms-comment__user {
              color: @color-1;
            }
            .joms-comment__actions {
              padding: 10px 0 0;
              a {
                color: @color-1;
              }
            }
          }
        }
      }

    }
    .joms-media--video {
      .tzautoplay {
        background: transparent;
      }
      .mejs-overlay-button {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        margin: -30px 0 0 -30px;
        &:hover {
          i {
            .animation(icon-video 1s infinite);
          }
        }
      }
    }
    .joms-media--image {
      img {
        width: 100%;
      }
    }
    .joms-media-event {
      background: none repeat scroll 0 0 #f6f6f6;
      margin: 38px 0 0;
      padding: 0;
      .event-left {
        float: left;
        i {
          font-size: 30px;
          padding: 25px 35px 0;
        }
      }
      .event-right {
        float: left;
        padding: 35px 0 22px;
        .joms-text--title {
          a {
            font-size: 17px;
            color: @color-1;
          }
        }
        .joms-list {
          .joms-list__item {
            padding-top: 15px;
            color: @color-6;
            font-size: 13px;
            &:first-child {
              padding-top: 0;
            }
            i {
              color: @color-4;
              margin: 0 10px 0 0;
            }
          }
        }
      }
      &:after {
        clear: both;
        content: "";
        display: block;
      }
    }

    .joms-media--images,
    .joms-media--image,
    .joms-media--video {
      padding-top: 40px;
      background: transparent;
      max-width: none;
      margin: 0;
    }
  }
  .joms-stream__header, .joms-stream__body {
    padding: 0;
    width: auto;
    float: left;
  }
}

.joms-stream__container {
  border-top: 1px solid @color-3;
  padding-top: 35px;
  margin-top: 35px;
  .joms-stream {
    border-bottom: 1px solid @color-3;
    padding-bottom: 47px !important;
    margin-bottom: 0 !important;
    padding-top: 32px !important;
    &:first-child {
      padding-top: 0 !important;
    }
  }
}

.load-more {
  background: @color-3;
  margin: -7px 0 5px;
  padding: 37px 0;
  text-align: center;
  color: #777777 !important;
  font-size: 14px;
  line-height: 25.2px;
  border: none;
  text-transform: uppercase;
  display: block;
  width: auto;
  border-radius: 0;
  &:hover, &:focus {
    background: @color-3;
    color: @color-1 !important;
  }
  i {
    padding: 0 20px 0 0;
  }

}

.loading {
  margin: 30px auto auto;
}

/*-------------- Social-Group -------------*/
.joms-gap {
  display: none;
}

.jomsocial-wrapper {
  .jomsocial {
    background: transparent;
    min-height: 0;
    height: auto;
    padding: 0;
    .joms-page {
      padding: 0;
      background: transparent;
      .joms-list__search {
        margin: 0 0 5px;
      }
      .joms-subnav--desktop {
        background: transparent;
        border: medium none;
        display: block;
        height: auto;
        margin-bottom: 5px;
        min-height: 0;
        overflow: hidden;
        > ul {
          min-height: 0;
          height: auto;
          > li {
            display: inline-block;
            padding: 0;
            &:hover, &.active {
              > a {
                background: transparent;
              }
            }
            > a {
              text-transform: uppercase;
              font-size: 12px;
              line-height: 14.4px;
              color: #777777;
              padding: 0 10px 5px 0;
            }
          }
        }
      }
      .joms-list__utilities {
        display: inline;
        .joms-inline--desktop {
          .joms-input--search {
            width: auto;
            padding: 5px;
            margin-right: 10px;
          }
        }
      }
      .joms-sortings {
        display: inline-block;
        width: 65%;
        .joms-select {
          width: auto;
          padding-right: 5px;
          &:nth-child(2n+1) {
            margin-left: 0;
          }
        }
      }
      .joms-list--card {
        .joms-list__item {
          border-bottom: 1px solid @color-3;
          border-top: none;
          border-right: none;
          border-left: none;
          margin: 0;
          padding: 37px 0 20px;
          background: transparent;
          .joms-list__cover {
            display: inline-block;
            height: 70px;
            width: 70px;
            overflow: hidden;
          }
          .joms-list__content {
            padding: 0 0 0 100px;
            float: none;
            width: auto;
            .joms-list--table {
              li {
                width: auto;
                padding: 0 10px;
                &:first-child {
                  padding-left: 0;
                }
                &:last-child {
                  padding-right: 0;
                }
                i {
                  color: @color-4;
                  padding: 0 10px 0 0;
                }
                a {
                  &:hover {
                    text-decoration: none;
                  }
                  font-size: 13px;
                  color: @color-1;
                }
              }
            }
            .joms-list-group-desc {
              font-size: 13px;
              padding-bottom: 15px;
              color: #888888;
            }
            .joms-list__title {
              margin-bottom: 5px;
              a {
                &:hover {
                  text-decoration: none;
                }
                color: @color-1;
              }
            }
            .joms-list-group-create {
              margin-bottom: 7px;
              font-size: 13px;
            }
          }
        }
      }
      .joms-pagination {
        text-align: center;
      }
    }
  }
}

.tz-members {
  padding: 98px 0;
}

.tz-all-member {
  padding: 0 0 50px;
}

.tz-members-title {
  background: #f6f6f6;
  margin: 0 0 5px;
  padding: 28px 0 25px 30px;
  color: @color-1;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  text-transform: uppercase;
}

.tz-filter-member {
  background: #f6f6f6;
  padding: 15px 0 15px 30px;
  ul {
    margin: 0;
    li {
      margin: 0 !important;
      &.active, &:hover {
        a {
          color: @color-1;
        }
      }
      a {
        color: #777777;
        text-transform: uppercase;
        font-size: 12px;
        padding-right: 10px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.option1 {
      display: inline-block;
      li {
        float: left;
        padding: 0 5px;
        &:last-child {
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }
      }
    }
    &.joms-list--alphabet {
      float: right;
      margin-right: 10px;
    }
  }
}

.tz-filter-member .tz-option li a {
  font-size: 12px;
  line-height: 14.4px;
  color: #777777;
  padding-right: 10px;
}

.tz-filter-member .tz-option li a:hover {
  color: @color-1;
}

.tz-filter-member .tz-character {
  margin-right: 10px;
}

.tz-filter-member .tz-character li a {
  font-size: 12px;
  line-height: 14.4px;
  color: #777777;
  padding-right: 10px;
}

.tz-filter-member .tz-character li a:hover {
  color: @color-1;
}

.tz-all-member ul {
  margin-bottom: 0;
}

.tz-members-wrapper {
  padding: 37px 0 30px;
  border-bottom: 1px solid @color-3;
  margin: 0;
}

.tz-members-wrapper:after {
  display: block;
  clear: both;
  content: '';
}

.tz-detail-member {
  .tz-info {
    padding: 0 0 0 35px;
    h5 {
      margin: 0 0 8px;
      a {
        color: @color-1;
        font-weight: 400;
        line-height: 18px;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    p {
      font-size: 13px;
      color: #888888;
      line-height: 15.6px;
      margin: 0;
      padding: 0 0 9px;
      min-height: 25px;
    }
  }
  .tz-avatar {
    .joms-avatar {
      display: block;
      img {
        width: 70px;
        height: 70px;
        max-height: 70px;
        max-width: 70px;
        background: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
      }
    }
  }
  .tz-friend {
    display: inline-block;
    a, span {
      color: #333333;
      font-weight: 400;
      line-height: 15.6px;
      font-size: 13px;
      text-transform: uppercase;
    }
    .joms-list__button--dropdown {
      bottom: 0;
      display: inline-block;
      position: relative;
      right: 0;
      a {
        border: none;
        border-radius: 0;
        background: transparent;
      }
    }
    .joms-list__options {
      a {
        right: 20px;
        top: 35px;
      }
      .joms-dropdown {
        right: 20px;
        top: 55px;
        li a {
          font-size: 12px;
        }
      }
    }
  }
}

/*-------------- Single Group & Member -------------*/
.joms-single-member,
.joms-single-group {
  .joms-focus__header {
    background: transparent;
    bottom: 15px;
    padding: 0 20px;
    .joms-avatar--focus {
      padding: 0 20px;
      z-index: 999999;
      img {
        border-radius: 0;
        padding: 0;
        border: 8px solid @white;
        max-width: 130px;
      }
      svg {
        left: 44px;
        top: 44px;
      }
    }
    .joms-focus__title {
      width: auto;
      padding: 0;
    }
    .joms-focus__actions__wrapper {
      display: inline-block;
      width: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .joms-focus__cover-image {
    &::after {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .joms-focus__link {
    background: @color-3;
    position: relative;
    z-index: 9999;
    border: none;
    padding-left: 180px;
    padding-right: 20px;
    li {
      padding: 10px 15px 20px 0;
      border: none;
      &:first-child {
        padding-left: 15px;
      }
      a {
        color: @color-5;
        font-weight: 400;
        font-size: 13px;
        &:hover, &:active, &.active {
          color: #666666;
        }
      }
      &.pull-right {
        padding-right: 8px;
        a {
          color: #666666;
        }
        i {
          padding-right: 6px;
        }
      }
    }
  }
  .tz_joms_interaction {
    bottom: 70px;
    display: block;
    position: absolute;
    width: auto;
    z-index: 999999;
    right: 10px;
    .joms-focus__actions--desktop {
      display: block;
    }
    .joms-focus__button--message,
    .joms-focus__button--add {
      border: none;
      border-radius: 0;
      padding: 0;
      background: transparent;
      text-transform: uppercase;
      font-size: 12px;
      &:hover {
        background: transparent;
      }
      i {
        padding: 0 5px 0 10px;
      }
    }
  }

  .joms-main {
    width: 100%;
    padding: 0;
    .joms-stream {
      //      padding: 21px 0 47px !important;
      .joms-comment__reply {
        display: table;
      }
      .stream-content {
        padding-top: 40px;
        margin: 0;
      }
    }
  }
}

/*-------------- Social-Login -------------*/
.tz-customer {
  padding: 100px 0;
}

.tz-customer-login {
  padding: 0;
}

.tz-customer-login h4 {
  background: #f6f6f6;
  color: @color-1;
  font-family: "Varela", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  margin: 0 0 5px;
  padding: 25px 0 28px 30px;
}

.tz-customer-login form p {
  margin: 0 0 55px;
}

.tz-customer-login form {
  background: #f6f6f6;
  padding-left: 30px;
}

.tz-customer-login input {
  background: @white;
}

.tz-customer-login .controls button {
  margin-right: 5px;
}

/*-------------- Social-Single Member -------------*/

.tz-member-header {
  background-image: url("demos/Social/images-2-Social-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 265px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.tz-member-header:after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}

.tz-member-join {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.tz-member-join .tz-author-name {
  font-family: 'Varela', sans-serif;
  line-height: 36px;
  margin: 0 0 0 -20px;
  font-size: 30px;
  color: @white;
  font-weight: 400;
}

.tz-member-join .tz-user-name {
  font-family: 'Varela', sans-serif;
  line-height: 36px;
  margin: 0 0 0 -20px;
  font-size: 30px;
  color: @white;
  font-weight: 400;
}

.tz-member-join .tz-send-messages {
  margin: 10px 10px 0;
  text-align: right;
}

.tz-member-join .tz-send-messages a {
  color: @white;
  font-family: 'Varela', sans-serif;
  font-weight: 400;
  line-height: 14.4px;
  font-size: 12px;
  margin: 0 5px 0 0;
}

.tz-member-join .tz-send-messages a i {
  padding: 0 5px 0;
}

.tz-member-link {
  background: @color-3;
  padding: 5px 0;
}

.tz-member-link .tz-images-member {
  border: 8px solid @white;
  margin: -107px 20px 6px 40px;
  position: relative;
}

.tz-member-link .tz-images-member a {
  display: inline-block;
}

.tz-member-link .tz-about-me {
  margin: 0;
}

.tz-member-link .tz-about-me li {
  display: inline;
}

.tz-member-link .tz-about-me li a {
  font-size: 13px;
  color: @color-5;
  line-height: 28.8px;
  padding: 0 5px 0 0;
  font-family: 'Varela', sans-serif;
  font-weight: 400;
}

.tz-member-link .tz-about-me li a.tz-active {
  color: #666666;
}

.tz-member-link .tz-member-action span {
  display: inline-block;
  margin: 0 20px 0 0;
}

.tz-member-link .tz-member-action span a {
  font-size: 13px;
  color: #666666;
  line-height: 20.8px;
  font-family: 'Varela', sans-serif;
  font-weight: 400;
  margin-right: 10px;
}

.tz-member-link .tz-member-action span a i {
  padding: 0 10px 0 0;
}

/*-------------- Social-Groups -------------*/
.tz-groups {
  padding: 98px 0 140px;
}

.tz-all-groups {
  padding: 0 0 70px;
}

.tz-groups-wraper {
  border-bottom: 1px solid @color-3;
  margin: 0 0 -8px;
  padding: 37px 0 20px;
}

.tz-detail-member .tz-info-groups {
  margin: 0 0 0 100px;
}

.tz-detail-member .tz-info-groups h5 {
  margin: 0 0 5px 0;
}

.tz-detail-member .tz-info-groups small {
  display: inline-block;
  font-size: 13px;
  line-height: 15.6px;
  color: #555555;
  font-family: 'Varela', sans-serif;
  font-weight: 400;
  margin: 0 0 7px 0;
}

.tz-detail-member .tz-info-groups p {
  color: #888888;
  line-height: 19.5px;
  padding: 0 0 14px;
}

.tz-detail-member .tz-info-groups .tz-friend {
  margin: 0 0 -20px 0;
}

.tz-detail-member .tz-info-groups .tz-friend a {
  color: @color-1;
  padding: 0 10px 0;
}

.tz-detail-member .tz-info-groups .tz-friend a:first-child {
  padding-left: 0;
}

.tz-detail-member .tz-info-groups .tz-friend a i {
  color: @color-4;
  padding: 0 10px 0 0;
}

/*-------------- Social-Single Group -------------*/
.tz-single-social {
  padding-top: 38px;
}

.tz-social-group {
  padding: 100px 0 110px;
}

.tz-groups-join {
  background-image: url("demos/Social/images-1-Social-2.jpg");
}

.tz-filter-group {
  margin: 12px 0 0;
  padding: 14px 0 14px 30px;
}

.tz-list-group > li:first-child {
  border-top: none;
  padding-top: 21px;
}

.tz-list-group > li:last-child {
  border-bottom: 1px solid @color-3;
  margin-bottom: 36px;
}

/*-------- Blog ----------*/
.TzUserArticleInfo,
.TzTagArticleInfo,
.TzArticleBlogInfo {
  > div:first-child {
    .TzLine {
      display: none;
    }
  }
  .tz-row {
    > div:first-child {
      .TzLine {
        display: none;
      }
    }
  }
  > *, .content_rating {
    display: inline-block;
  }
  color: #bababa;
  display: inline-block;
  font-size: 12px;
  line-height: 21.6px;
  margin: 0 0 10px;
  .TzLine {
    padding: 0;
  }
  .TzUserCreatedby,
  .TzTagCreatedby,
  .TzCreatedby,
  .TzBlogCreatedby {
    a {
      margin: 0 15px 0 10px;
      color: @color-6;
      &:hover {
        color: #334878;
      }
    }
  }
  .TzUserCreate,
  .TzTagDate,
  .TzCreate,
  .TzBlogCreate {
    .date {
      margin: 0 15px 0 10px;
      color: @color-6;
      &:hover {
        color: #334878;
      }
    }
  }
  .TzVote {
    .content_rating {
      margin: 0 15px 0 10px;
      a {
        color: @color-6;
        &:hover {
          color: #334878;
        }
      }
    }
  }
  .TzTagHits,
  .TzHits,
  .TzBlogHits {
    .hits {
      margin: 0 15px 0 10px;
      color: @color-6;
      &:hover {
        color: #334878;
      }
    }
  }
  .TzArticleCategory,
  .TzBlogCategory {
    .category {
      margin: 0 15px 0 10px;
      color: #bababa;
      a {
        color: @color-6;
        &:hover {
          color: #334878;
        }
      }
    }
  }
  .TZCommentCount,
  .TzPortfolioCommentCount {
    .comment {
      margin: 0 15px 0 10px;
      color: @color-6;
      &:hover {
        color: #334878;
      }
    }
  }
  .TzTag {
    padding-right: 10px;
    .TzLine {
      padding-right: 5px;
    }
    a {
      color: @color-6;
      &:hover {
        color: #334878;
      }
    }
  }
}

.TzUser,
.TzTag,
.TzBlog {
  &.blog-4-column {
    .TzItemsRow .blog-padding > * {
      padding-left: 10px;
      padding-right: 10px;
    }
    .TzItem {
      .TzBlogMedia {
        margin-bottom: 15px;
      }
      .TzReadmore {
        span {
          padding: 10px;
        }
        i {
          padding-left: 0;
        }
      }
      .TzBlogTitle i {
        padding-right: 10px;
      }
    }
  }
  .TzItemsRow {
    .TzItem {
      padding: 45px 0 30px;
      margin: 0;
      border-bottom: 1px solid @color-3;
    }
    &:first-child {
      .TzItem {
        padding-top: 0;
      }
    }
    .blog-padding > * {
      padding-left: 15px;
      padding-right: 15px;
    }
    .blog-padding > .TzBlogMedia {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .TzItem {
    margin: 0;
    .TzUserMedia,
    .TzTagMedia,
    .TzBlogMedia {
      margin-bottom: 25px;
    }
    .TzUserTitle,
    .TzTagTitle,
    .TzBlogTitle {
      margin: 4px 0 10px;
      a {
        font-size: 18px;
        color: @color-4;
        font-weight: 400;
        line-height: 32.4px;
        &:hover {
          text-decoration: none;
          color: #334878;
        }
      }
      i {
        font-size: 12px;
        padding-right: 15px;
      }
    }
    .TzDescription {
      p {
        color: @color-6;
        font-size: 14px;
        line-height: 25.2px;
        margin: 0;
        padding: 0 0 25px;
      }
    }
    .TzReadmore {
      display: inline-block;
      margin: 22px 0 0 0;
      overflow: hidden;
      position: relative;
      padding: 0;
      border-radius: 0;
      border: none;
      .box-shadow(none);
      &:after {
        background: none repeat scroll 0 0 rgba(149, 0, 29, 0);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .transition(all 0.5s linear 0s);
        .transform(rotate(45deg))
      }
      &:hover {
        &:after {
          background: #334878;
          transform: scale(8);
        }
        span {
          border-color: #334878;
          color: @white;
        }
      }
      span {
        display: inline-block;
        font-size: 14px;
        line-height: 25.2px;
        padding: 10px 24px 10px 10px;
        position: relative;
        .transition(all 0.5s linear 0s);
        z-index: 999;
        border: 1px solid @color-4;
        color: @color-4;
        text-transform: uppercase;
        i {
          color: @color-4;
          padding: 0 15px;
          transition: all 0.3s linear 0s;
        }
        &:hover {
          i {
            .animation(0.3s linear 0s normal none 1 running scale-icon);
            color: @white;
          }
        }
      }
    }
  }
}

.tz_portfolio_user {
  .media-heading {
    a {
      color: @color-4;
    }
  }
  .AuthorSocial {
    > span {
      display: block;
      a {
        color: @color-4;
      }
    }
    .TzLine {
      display: none;
    }
  }
}

/*--------- Module Right, Left ---------*/
.mod-right {
  padding-top: 28px;
}

.tz-bog-search {
  position: relative;
  input {
    background: none repeat scroll 0 0 #f6f6f6;
    border: medium none;
    color: #aaaaaa;
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    padding: 15px 30px 15px 10px;
    width: 100%;
  }
  label {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 18px;
    i {
      color: @color-4;
    }
  }
}

ul {
  &.widget {
    position: relative;
    z-index: 9;
    border: 1px solid #eeeeee;
    margin: 0;
    li {
      border-bottom: 1px solid #eeeeee;
      overflow: hidden;
      position: relative;
      margin: 0;
      .transition(all 0.3s linear 0s);
      &.current,
      &.active,
      &.open {
        a {
          background: transparent;
          color: @color-4;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        padding: 20px;
        position: relative;
        z-index: 9992;
        border-radius: 0;
        .transition(all 0.3s linear 0s);
        background: transparent;
        color: @color-4;
        &:hover {
          background: transparent;
        }
        .tzicon-1 {
          padding-right: 20px;
          color: @color-4;
        }
        .tzicon-2 {
          color: @color-1;
          left: 21px;
          position: absolute;
          top: 25px;
          .transition(all 0.3s linear 0s);
          .transform(scale(0))
        }
      }
      &:after {
        background: none repeat scroll 0 0 rgba(238, 238, 238, 0);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
        .transition(all 0.3s linear 0s);
      }
      &.current,
      &.active,
      &:hover {
        &:after {
          background: none repeat scroll 0 0 rgba(238, 238, 238, 1);
          width: 100%;
        }
        a .tzicon-2 {
          .transform(scale(1))
        }
        .tzicon-1 {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
}

.latestnews {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  li {
    border-bottom: 1px solid #eeeeee;
    padding: 19px 15px;
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      padding: 0;
      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        padding: 25px 20px;
        text-transform: uppercase;
        i {
          padding-right: 15px;
          top: 1px;
          position: relative;
        }
      }
    }
    .media_image {
      width: 70px;
      float: left;
      img {
        width: 100%;
      }
    }
    .info_lastest {
      display: table-cell;
      padding: 0 0 0 15px;
      h5 {
        margin: 0;
        a {
          color: @color-4;
          font-size: 15px;
          font-weight: 400;
          .transition(all 0.3s linear 0s);
          line-height: 1.5em;
          &:hover {
            color: #334878;
            text-decoration: none;
          }
        }
      }
      .created {
        color: #BBBBBB;
        font-size: 12px;
        line-height: 21.6px;
        font-weight: 400;
      }
    }
  }
}

/*------------ Single Blog ------------*/
.TzItemPage {
  .TzArticleMedia {
    margin: 0;
    img {
      width: 100%;
    }
    .flexslider {
      border: none;
      margin: 0;
    }
  }
  .TzArticleTitle {
    padding: 20px 0 24px;
    margin: 0;
    i {
      font-size: 12px;
    }
  }
  .TzArticleFullText {
    p {
      color: #999999;
      line-height: 25.2px;
      margin: 0;
      padding: 0 0 25px;
    }
  }
  .TzArticleTag {
    margin: 0;
    text-align: right;
    span {
      a {
        background: #f6f6f6;
        color: @color-4;
        display: inline-block;
        font-size: 13px;
        line-height: 23.4px;
        padding: 5px 10px;
        .transition(all 0.3s linear 0s);
        font-weight: 400;
        text-transform: capitalize;
        border-radius: 0;
        &:hover {
          background: none repeat scroll 0 0 @color-4;
          color: @white;
        }
      }
    }
  }
  .breadcrumb.tz_portfolio_like_button {
    background: transparent;
    .TzLikeButtonInner {
      .TwitterButton, .GooglePlusOneButton, .PinterestButton, .LinkedinButton {
        position: relative;
        top: 4px;
        padding: 0;
      }
    }
  }
  .tz_portfolio_user {
    margin: 0;
    text-align: center;
    padding: 25px 0;
    .AuthorAvatar {
      display: inline-block;
      float: none !important;
      margin: 0;
      padding: 0;
      &:hover {
        img {
          .transform(rotate(-20deg));
        }
      }
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        .transition(all 0.3s linear 0s);
      }
    }
    .media-body {
      width: auto;
      display: inline-block;
      vertical-align: middle;
      padding-left: 20px;
      text-align: left;
      h4 {
        a {
          color: @color-4;
          font-size: 14px;
          line-height: 16.8px;
          text-transform: uppercase;

        }
      }
      .AuthorSocial {
        .AuthorUrl {
          a {
            color: #999999;
            font-size: 13px;
            line-height: 20.8px;
          }
        }
      }
    }
  }
}

div#comments {
  padding-top: 0;
  width: auto;
  p {
    color: #999999;
    font-size: 14px;
    line-height: 25.2px;
    margin: 5px 0 0;
  }
  .count_cm {
    margin-top: 7px;
    padding-bottom: 4px;
  }
  h3.comment_header {
    float: left;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 25px;
    margin: 0;
  }
  .leave_comment {
    line-height: 1;
    margin: 0;
    position: relative;
    top: -5px;
    &:hover {
      color: #462800;
    }
  }
  .comments_form {
    position: relative;
    top: -4px;
  }
  a {
    color: #462800;
    &:hover {
      color: #462800 !important;
    }
  }
  div.comment-avatar {
    margin-right: 20px;
    margin-top: 0;
    img {
      width: 60px;
    }
  }
  .comment-content {
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    color: #000000;
    display: table-cell;
  }
  div#comments-list-0 {
    margin-left: 0;
    margin-top: 40px;
    #comments-list-1 {
      padding-left: 40px;
    }
    #comments-list-2 {
      padding-left: 80px;
    }
    .comments-list {
      margin-top: 35px;
      border-top: 1px solid #ebebeb;
      padding-top: 35px;
      margin-left: 0;
    }
    .comment_item {
      padding-bottom: 25px;
      border-bottom: 1px solid @color-3;
      margin-bottom: 28px;
      position: relative;
    }
    #comments-list-1 {
      margin: 0;
      padding-top: 0;
      border: none;
      .comment_item {
        padding-bottom: 25px;
        border-bottom: 1px solid @color-3;
        margin-bottom: 28px;
      }
    }
  }
  #comments-inline-edit {
    border: none;
  }
  .comment-author {
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    font-size: 14px;
    position: relative;
    .j-date {
      margin: 10px 0;
      display: block;
      font-size: 13px;
      color: #aaaaaa;
    }
    .j-author {
      color: @color-4;
    }

  }
  span.comment-meta {
    float: right;
    color: #999999;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 0;
    a {
      color: @color-1;
      line-height: 22px;
      font-weight: 300;
      font-size: 14px;
      margin: 0 10px;
      &.comment-quote {
        margin-right: 0;
      }
      &:hover {
        color: @color-1 !important;
      }
    }
  }
  .quote, blockquote, br {
    display: none;
  }
  #comments-list-footer {
    margin: 40px 0 0 0 !important;
    a {
      &:hover {
      }
    }
  }
  #comments-form {
    margin-top: 20px !important;
    #comments-form-cancel {
      .btn {
        background: @white;
        padding: 10px 35px !important;
        color: @color-4;
        .transition(all 0.3s linear 0s);
        &:hover {
          color: @white;
        }
      }
    }
    .btn {
      background: transparent;
      padding: 10px 35px !important;
      line-height: 1;
      color: @color-4;
      text-transform: uppercase;
      border-radius: 0;
      position: relative;
      left: 15px;
      border: 1px solid @color-4;
      margin-top: 35px;
      i {
        padding-right: 15px;
        color: @color-4;
      }
    }
    label {
      color: #666666;
      font-size: 14px;
      font-weight: 400;
      line-height: 25.2px;
      padding: 0 0 7px;
      display: block;
    }
    .form-group {
      position: relative;
      margin-bottom: 35px;
      .form-control {
        background-color: @white;
        background-image: none;
        border: 1px solid rgba(70, 40, 0, 0.1);
        border-radius: 2px;
        .box-shadow(none);
        display: block;
        font-size: 13px;
        line-height: 22px;
        padding: 10px 15px !important;
        font-weight: 400;
        width: 100%;
        color: #462800;
        height: auto;
        margin: 0;
        input {
          min-height: 40px;
        }
        textarea {
          min-height: 120px;
        }
      }
    }
    textarea.form-control {
      background-color: @white;
      background-image: none;
      border: 1px solid rgba(70, 40, 0, 0.1);
      border-radius: 2px;
      .box-shadow(none);
      display: block;
      font-size: 14px;
      line-height: 22px;
      padding: 10px 15px !important;
      font-weight: 400;
      width: 100%;
      max-width: none !important;
      color: inherit;
      min-height: 140px;
      margin: 0;
    }
  }
  .grippie {
    display: none;
  }
  .title_comment_form {
    font-size: 24px;
    font-weight: 700;
    color: #462800;
  }
  #comments_content {
    margin-bottom: 30px;
  }
}

/*--------- Time Line -------------*/
.oe-timeline-container::before {
  background: none repeat scroll 0 0 #dddddd;
  content: "";
  height: 100%;
  left: 50%;
  margin-left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.ss-container::before {
  background: none repeat scroll 0 0 #dddddd;
  content: "";
  height: 100%;
  left: 50%;
  margin-left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.ss-container {
  //  overflow: visible;
  float: none;
  .oe-blog-item {
    .oe-blog-ele {
      margin-bottom: 25px;
    }
    .oe-pad-normal {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 22px;
      margin-bottom: 3px;
    }
  }
  .TzBlogTitle {
    text-shadow: none;
    margin: 4px 0 10px;
    a {
      color: @color-4;
      font-size: 18px;
      font-weight: 400;
      line-height: 32.4px;
      display: inline;
      &:hover {
        color: @color-1;
      }
    }
    i {
      font-size: 12px;
      padding-right: 15px;
    }
  }
}

.oe-timeline-container {
  margin: 0;
  .oe-timeline-event {
    position: relative;
  }
}

.oe-timeline-container .oe-timeline-event + .oe-timeline-event {
  margin-top: 60px;
}

.oe-timeline-container .oe-timeline-event .oe-timeline-mark {
  position: absolute;
  top: 0;
  display: block;
  width: auto;
  height: auto;
  background: @white;
  opacity: 0;
  text-align: center;
  border: 1px solid #dddddd;
  padding: 10px 20px;
  .transition(all ease 0.3s);
}

.oe-timeline-container .oe-timeline-event .oe-timeline-mark .btn {
  height: 100%;
  line-height: 100%;
}

.oe-timeline-container .oe-timeline-event.left .oe-timeline-mark {
  right: 66px;
  opacity: 1;
  .transform(translate(100%, 0));
}

.oe-timeline-container .oe-timeline-event.right .oe-timeline-mark {
  left: 66px;
  opacity: 1;
  .transform(translate(-100%, 0));
}

.oe-blog-ele.oe-banner-holder > img {
  width: 100%;
}

.left {
  .oe-blog-item {
    padding-right: 95px;
  }
}

.right {
  .oe-blog-item {
    padding-left: 95px;
  }
}

/*--------- Page --------*/

.profile-edit, .profile, .newsfeed-category, .remind, .reset, .login, .registration {
  .page-header {
    margin: 0;
    border: none;
    h1 {
      color: @color-4;
      font-size: 25px;
      font-weight: 400;
      line-height: 45px;
      margin: 0;
      padding: 0 0 5px;
      text-decoration: none !important;
      text-transform: uppercase;
      i {
        font-size: 12px;
        padding-right: 10px;
      }
    }
  }
  .form-horizontal {
    padding: 45px 0;
    .control-label {
      text-align: left;
      label {
        margin-top: 10px;
        font-weight: 400;
        color: #999999;
      }
    }
    .checkbox {
      label {
        padding: 0;
        color: #999999;
      }
      input {
        width: auto;
        margin: 5px 0 0 10px;
      }
    }
    input {
      background: #f6f6f6 none repeat scroll 0 0;
      border: medium none;
      font-size: 13px;
      margin: 10px 0 0;
      padding: 8px 10px;
      width: 100%;
    }
    .btn-reset,
    .btn-login {
      padding: 15px 30px;
      background: @white;
      border: 1px solid @color-4;
      .box-shadow(none);
      text-shadow: none;
      margin-right: 25px;
      text-transform: uppercase;
      i {
        padding-right: 15px;
      }
      &:hover {
        background: #334878;
        border-color: #334878;
        color: @white;
        i {
          color: @white;
        }
      }
    }
    .button-reset {
      padding-left: 15px;
    }
    .button-login {
      text-align: right;
    }
    .other-links {
      ul {
        padding: 5px 0 0;
        list-style: none;
        li {
          padding-bottom: 10px;
          a {
            color: #999999;
          }
        }
      }
    }
  }
}

.login .form-horizontal {
  .button-login {
    margin: 0;
  }
  .btn-login {
    margin: 10px 0 0;
  }
}

.profile {
  dd {
    font-weight: 400;
    color: @color-6;

  }
  dt {
    color: @color-4;
    text-align: left;
  }
}

#member-profile {
  .control-label {
    text-align: left;
    label {

    }
  }
  .controls {
    input {
      background: #f6f6f6 none repeat scroll 0 0;
      border: medium none;
      font-family: "Varela", sans-serif;
      font-size: 13px;
      margin: 0;
      padding: 8px 15px;
      width: 100%;
    }
  }
  .form-actions {
    .btn {
      background: @white;
      border: 1px solid @color-4;
      border-radius: 0;
      color: @color-4;
      padding: 8px 30px;
      position: relative;
      overflow: hidden;
      span {
        display: inline-block;
        position: relative;
        transition: all 0.4s ease-out 0s;
        z-index: 10;
      }
      &:before {
        background-color: @color-1;
        color: @white;
        content: "";
        display: block;
        height: 100%;
        left: -100%;
        opacity: 0.5;
        position: absolute;
        top: 0;
        transition: all 0.4s ease-out 0s;
        width: 100%;
        z-index: 1;
      }
      &:hover {
        border-color: @color-1;
        &:before {
          left: 0;
          opacity: 1;
        }
        span {
          color: @white;
        }
      }
    }
  }
}

/*-------- Home 3 ---------*/
.courses_portfolio {
  .portfolio-item {
    overflow: hidden;
    background: #f7f7f7;
    &.right {
      .tz-img, .tz-description {
        float: right;
      }
    }
    .tz-img {
      width: 50%;
      float: left;
      overflow: hidden;
      img {
        position: relative;
      }
    }
    .tz-description {
      width: 50%;
      float: left;
      padding: 45px 20px 0;
      position: relative;
      h4 {
        margin: 0;
        a {
          color: @color-4;
          font-size: 16px;
          font-weight: 400;
          line-height: 28.8px;
          margin: 0;
          transition: all 0.3s linear 0s;
        }
      }
      p {
        color: #868686;
        font-size: 13px;
        font-weight: 400;
        line-height: 23.4px;
        margin: 0;
        padding-top: 15px;
      }
      span {
        padding-top: 20px;
        display: block;
        a {
          background: @white;
          color: @color-4;
          float: left;
          font-size: 13px;
          line-height: 23.4px;
          padding: 5px 10px;
          margin: 0 5px 5px 0;
          &:hover {
            background: @color-4;
            color: @white;
          }
        }
      }
      > a {
        display: block;
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        span {
          background: #eeeeee;
          color: rgb(68, 68, 68);
          display: block;
          font-size: 14px;
          line-height: 25.2px;
          padding: 17px 0;
          .transition(all 0.3s linear 0s);
          i {
            padding-right: 20px;
          }
        }
        &:hover {
          span {
            background: #253b48 none repeat scroll 0 0;
            color: @white;
          }
        }
      }
    }
  }
}

.tz-portfolio-project {
  background: #de5749;
  text-align: center;
  a {
    color: #ffffff;
    display: inline-block;
    line-height: 25.2px;
    padding: 37px 0;
    i {
      margin-right: 20px;
    }
    &:hover {
      i {
        .animation(2s linear 0s normal none infinite running rotate-icon);
      }
    }
  }
}

.tz-training-images {
  position: relative;
  margin-bottom: 30px;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    &:after {
      background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    a.a_href {
      border: 1px solid @white;
      border-radius: 2px;
      display: inline-block;
      height: 45px;
      left: 50%;
      margin-left: -24px;
      margin-top: -24px;
      padding: 10px;
      position: absolute;
      top: 50%;
      .transform (scale(0));
      .transition(all 0.3s linear 0s);
      width: 45px;
      z-index: 999;
      i {
        color: @white;
      }
      &:hover {
        border-radius: 50%;
      }
    }
    .tz-training-content {
      color: @white;
      left: 0;
      position: absolute;
      text-align: center;
      top: 30%;
      .transition(all 0.3s linear 0s);
      width: 100%;
      z-index: 9991;
      a {
        color: @white;
        font-size: 16px;
      }
      .mod_events_latest_date {
        font-size: 13px;
      }
    }
  }
  img {
    width: 100%;
  }
  &:hover {
    a.a_href {
      .transform(scale(1));
    }
    .tz-training-content {
      .transform(scale(0));
    }
  }
}

.cus_home3 {
  .tz-information-image {
    img {
      width: 100%;
    }
  }
  .tz-information-content {
    background: rgb(222, 87, 73);
    .block1 {
      text-align: center;
      padding: 35px 0;
      h4 a, i {
        color: @white;
      }
      i {
        font-size: 12px;
      }
      h4 {
        margin: 0;
        padding-top: 10px;

      }
    }
    .block2 {
      ul {
        padding: 27px 0 26px;
        margin: 0;
        li {
          color: @white;
          padding-bottom: 10px;
          small {
            font-size: 13px;
          }
          i {
            font-size: 12px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

/*----- Video -------*/
.TzStatistic {
  margin: 0 -15px;
  height: 550px;
  overflow: hidden;
  //  &:after {
  //    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  //    content: "";
  //    height: 100%;
  //    left: 0;
  //    position: absolute;
  //    top: 0;
  //    width: 100%;
  //    z-index: 9;
  //  }
  .img_video {
    z-index: 99;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    img {
      position: relative;
    }
  }
  > img {

    width: 100%;

  }
  .bg-image {
    width: 100%;
  }
  video {
    position: relative;
  }
  .video-content {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 999;
    .tz-video-content {
      display: table;
      height: 100%;
      width: 100%;
      .tz {
        display: table-cell;
        vertical-align: middle;
        h3 {
          color: #ffffff;
          font-size: 80px;
          line-height: 80px;
          margin: 13px 0 12px;
          padding: 0;
          text-align: center;
          text-decoration: none;
        }
        small {
          color: rgb(255, 255, 255);
          display: block;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          margin-bottom: 17px;
          padding: 0;
          text-align: center;
          text-decoration: none;
        }
        span {
          background: rgb(255, 255, 255) none repeat scroll 0 0;
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 5px;
          margin-bottom: 10px;
          position: relative;
          width: 5px;
        }
      }
    }
  }
  .tzpause,
  .tzautoplay {
    background: #f26e4f;
  }
}

.tz-introduce-content {
  &.tz-introduce-content-style-7 {
    background: #ffffff;
    margin-top: -30px;
    padding: 58px 15px 52px;
    position: relative;
    z-index: 9900;
  }
}

/*-------- Home 2 ---------*/

/*-------- Home 1 ---------*/
/*---------- Login--------*/
.home-4 {
  form#login-form {
    background: @white;
    margin: 0 -15px 0 -10px;
    padding: 40px 25px 35px;
    .pretext {
      > h4 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        padding: 18px 0 0;
      }
      > div {
        color: rgb(153, 153, 153);
        display: block;
        font-size: 14px;
        padding: 4px 0 45px;
        text-align: center;
      }
    }
    .userdata {
      .form-group {
        text-align: left;
        margin-bottom: 10px;
        label {
          font-size: 13px;
          padding-bottom: 12px;
          margin: 0;
          font-weight: 400;
        }
        input {
          border-radius: 0;
          padding: 7px 20px;
          background: #eeeeee;
          border: 1px solid #eeeeee;
          .box-shadow(none);
          line-height: 2em;
          height: auto;
        }
      }
    }
    .btn-login {
      padding: 13px 23px;
      margin-top: 25px;
      display: inline-block;
      border: 1px solid @color-4;
      border-radius: 3px;

      input {
        background: transparent;
        border-radius: 0;
        border: none;
        .box-shadow(none);
        text-transform: uppercase;
      }
    }
  }
}

/*--------- Employee ------*/
.team-box {
  .team-img {
    position: relative;
    overflow: hidden;
    > img {
      width: 100%;
      .transition(all 0.3s linear 0s);
    }
    .tz-icon-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      a {
        background: @white;
        border-radius: 50%;
        display: block;
        height: 62px;
        position: relative;
        .transform(scale(0));
        .transition(all 0.3s linear 0s);
        width: 62px;
        z-index: 9999;
        margin: auto;
        i {
          font-size: 20px;
          padding: 20px 0;
          .transition(all 0.3s linear 0.3s);
          color: #334878;
        }
        &:hover {
          background: transparent;
        }
      }
    }
    &:hover {
      img {
        .transform(scale(1.1));
      }

    }
  }
  .team-details {
    text-align: center;
    padding-bottom: 30px;
    h5 {
      font-size: 16px;
      line-height: 28.8px;
      margin: 0 0 -12px;
      padding: 24px 0;
    }
    .t-position {
      color: #868686;
      font-size: 13px;
      line-height: 23.4px;
    }
  }
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
    .tz-icon-hover a {
      .transform(scale(1));
    }
  }
}

/*-------- Custom Text --------*/

.tz-introduce-content {
  text-align: center;
  h4 {
    color: rgb(68, 68, 68);
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    margin: 0;
    padding: 33px 0 28px;
  }
  p {
    color: #999999;
    line-height: 25.2px;
    margin: 0 0 35px;
  }
  i.tz_ethic_cross {
    display: block;
  }
}

.tz-introduce-content-style-2 {
  padding: 0;
  text-align: left;
  img {
    width: 100%;
  }
  h3 {
    margin: 0;
    padding: 26px 0 10px;
    a {
      color: #444444;
      font-size: 24px;
      font-weight: 400;
      line-height: 28.8px;
    }
  }
  p {
    color: #999999;
    line-height: 25.2px;
    margin: 0;
  }
  > a {
    display: inline-block;
    margin-top: 26px;
    overflow: hidden;
    position: relative;
    text-align: left;
    &:after {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: -100%;
      position: absolute;
      top: 0;
      transition: all 0.3s linear 0s;
      width: 100%;
    }
    span {
      border: 1px solid rgb(68, 68, 68);
      color: rgb(68, 68, 68);
      display: inline-block;
      line-height: 25.2px;
      padding: 10px 25px;
      position: relative;
      z-index: 99;
      i {
        display: inline-block;
        padding-right: 20px;
        color: rgb(68, 68, 68);
      }
    }
    &:hover {
      &:after {
        background: rgba(0, 0, 0, 1) none repeat scroll 0 0;
        left: 0;
      }
      span, span i {
        color: @white;
      }
    }
  }
}

.tz-institution {
  span {
    background: rgb(232, 190, 108) none repeat scroll 0 0;
    display: inline-block;
    padding: 59px 30px 45px;
    img {
      display: inline-block;
      margin-top: -8px;
    }
  }
  p {
    color: rgb(119, 119, 119);
    display: inline-block;
    font-size: 22px;
    line-height: 26.4px;
    padding-left: 40px;
    padding-top: 64px;
  }
  strong {
    color: rgb(119, 119, 119);
    font-weight: 400;
    line-height: 16.8px;
    padding-left: 15px;
  }
}

.tz-university-one {
  background: #f6f6f6 none repeat scroll 0 0;
  margin-right: -15px;
  padding: 40px 20px 45px 20px;
  text-align: center;
  h4 {
    margin: 0 0 -25px;
    padding: 25px 0 20px;
  }
  i.tz_ethic_cross {
    display: inline-block;
    padding: 33px 0 0;
  }
  p {
    color: #999999;
    margin: 0;
    line-height: 23px;
  }
}

.tz-introduce-content-style-3 {
  margin-top: -45px;
  padding: 60px 60px 48px 60px;
  position: relative;
  z-index: 99;
  text-align: center;
  background: @white;
  .tz-introduce-logo {
    background: rgb(40, 126, 122) none repeat scroll 0 0;
    border-radius: 50%;
    height: 68px;
    left: 50%;
    margin-left: -34px;
    padding: 14px 0;
    position: absolute;
    top: -34px;
    width: 68px;
    z-index: 99;
  }
  h4 {
    color: rgb(68, 68, 68);
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    margin: 0;
    padding: 38px 0 28px;
  }
}

.custom-image {
  position: relative;
  > img {
    width: 100%;
  }
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    content: "";
    left: 0;
  }
}

.tz-about-school {
  background: @white;
  margin: 0;
  overflow: hidden;
  padding-bottom: 15px;
  text-align: center;
  .tz_ethic_cross {
    font-size: 12px;
  }
  span {
    display: block;
    margin-bottom: 5px;
    position: relative;
    img {
      width: 100%;
    }
    a {
      background: #334878 none repeat scroll 0 0;
      border-radius: 50%;
      height: 62px;
      left: 50%;
      margin: -31px 0 0 -31px;
      position: absolute;
      top: 50%;
      width: 62px;
      i {
        color: @white;
        font-size: 20px;
        padding: 20px 0;
      }
    }
  }
  h4 {
    font-weight: 400;
    line-height: 21.6px;
    margin: 0 0 20px;
    padding: 10px 0 0;
  }
  p {
    color: #999999;
    font-weight: 400;
    line-height: 25.2px;
    margin: 0;
    padding: 0 15px 12px;
  }
}

.custom_article_2 {
  text-align: center;
  > i {
    display: block;
    font-size: 12px;
  }
}

.tz-item-more-details-style-2 {
  display: inline-block;
  margin-top: 50px;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    background: @color-4;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  span {
    border: 1px solid;
    color: rgb(68, 68, 68);
    display: inline-block;
    line-height: 25.2px;
    padding: 10px 25px;
    position: relative;
    z-index: 99;
    i {
      color: rgb(68, 68, 68);
    }
  }
  i {
    padding-right: 20px;
  }
  &:hover {
    &:after {
      right: 0;
    }
    span, span > i {
      color: @white;
    }
  }
}

.tz-address-style-4 {
  position: relative;
  > span {
    position: relative;
    display: block;
    img {
      width: 100%;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.tz-phone-fax {
  background: #334878;
  padding: 0 25px 45px 25px;
  position: absolute;
  right: 8.5%;
  top: 0;
  height: 100%;
  overflow: hidden;
  a {
    display: block;
    text-align: center;

    img {
      margin: 40px 0 31px;
      padding: 0;
    }
  }
  span {
    color: @white;
    font-size: 13px;
    line-height: 35.1px;

  }
  li {
    i {
      color: @white;
      font-size: 12px;
      padding-right: 10px;
    }
  }
}

.tz-introduce-content-style-4 {
  padding: 15px 0 0;
  text-align: center;
  p {
    padding-bottom: 0;
  }
}

.tz-introduce-content {
  h4 {
    color: rgb(68, 68, 68);
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    margin: 0;
    padding: 33px 0 28px;
  }
  p {
    color: #999999;
    line-height: 25.2px;
    margin: 0 0 35px;
  }
  i {
    color: #999999;

  }
}

/*--------- Latest Event ---------*/
.style-home-7 {
  .tz-event-wrapper.style-1 {
    .tz-content-event-item {
      i {
        padding-top: 27px;
      }
      h6 {
        padding: 20px 0 15px;
        color: rgb(68, 68, 68);
      }
      strong {
        color: rgb(68, 68, 68);
      }
    }
    .tz-image-event-item:hover::after {
      background: rgba(65, 86, 132, 0.8);
    }
  }
}

.parallax {
  height: 100%;
  left: 0;
  //  position: absolute;
  top: 0;
  width: 100%;
  background-attachment: fixed !important;
  background-position: 30% 10px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.image {
  left: 0;
  position: absolute;
  top: 55px;
}

.event-contents {
  text-align: center;
  h3.module-title {
    padding: 52px 0;
    margin: 0;
    color: @white;
  }
  .content {
    background: rgba(40, 40, 40, 0.4);
    padding-bottom: 68px;

    .mod_events_latest:last-child {
      i.tz_ethic_cross {
        display: none;
      }
    }
    > div {
      padding-bottom: 15px;
    }
    .tz-training-content {
      .mod_events_latest_content {
        display: block;
        a {
          color: @white;
          font-size: 16px;
          line-height: 19.2px;
          margin: 0;
          padding: 0 0 5px;
        }
      }
      .mod_events_latest_date {
        color: #fed27a;
        display: block;
        font-size: 13px;
        line-height: 23.4px;
        padding-bottom: 10px;
      }
      i {
        color: @white;
      }
    }
  }
}

.custom.image_event {
  margin-right: -15px;
  img {
    width: 100%;
  }
}

.event-home-5 {
  .row.mod_events_latest_table {
    margin: 0;
    > div {
      padding: 0;
    }
  }
  .module-ct {
    background: #e8be6c;
  }
  h3.module-title {
    background: #334878;
    color: @white;
    padding: 0 0 25px 0;
    margin: 0;
    i {
      padding: 20px 0;
    }
  }
  ul {
    padding: 0;
    border-top: 1px solid @white;
    margin: 0;
    border-right: 1px solid @white;
    li {
      padding: 16px 10px 25px;
      text-align: center;
      border-bottom: 1px solid @white;
      position: relative;
      &:last-child {
        border-bottom: none;
      }
      p {
        margin: 0;
      }
      &:hover {
        background: #d9af62;
        &.date {
          .readmore {
            .transform(scale(1));
          }
          span {
            .transform(scale(0));
          }
        }
      }
      * {
        color: @white;
      }
      &.date {
        padding: 22px 5px;
        span {
          font-size: 12px;
          display: block;
          .transition(all 0.3s ease 0s);
        }
        .readmore {
          display: block;
          position: absolute;
          .transform(scale(0));
          top: 20px;
          width: 100%;
          margin: auto;
          .transition(all 0.3s ease 0s);
          i {
            padding-right: 15px;
          }
        }
      }
      &.desc {
        padding: 23px 10px 28px;
      }
      .mod_events_latest_content {
        a {
          font-size: 16px;
        }
      }
      i.tz_ethic_cross {
        display: block;
        padding-bottom: 16px;
        font-size: 13px;
      }
    }
  }
  .mod_events_latest:last-child {
    ul {
      border-right: none;
    }
  }
}

.event-home-4 {
  .tz-mod-ethic {
    background: #334878;
    padding: 26px 0 22px;
  }
  .module-title {
    padding-bottom: 24px;
    margin: 0;
    color: @white;
  }
  .mod_events_latest_table {
    text-align: center;
    .mod_events_latest_content {
      display: block;
      a {
        color: @white;
        font-size: 16px;
        line-height: 19.2px;
        margin: 0;
        padding-bottom: 8px;
      }
    }
    .mod_events_latest_date {
      color: rgb(254, 210, 122);
      display: block;
      font-size: 13px;
      line-height: 23.4px;
      padding-bottom: 13px;
    }
    p {
      display: none;
    }
    i {
      font-size: 12px;
      color: @white;
      padding: 5px 0 10px;
      display: block;
    }
  }
}

#latest-event {
  > div {
    padding: 0;
  }
}

.tz-event-wrapper {

  &.style-1 {
    .mod_events_latest_first,
    .mod_events_latest {
      padding: 0 15px;
    }
    .tz-image-event-item {
      overflow: hidden;
      padding: 0;
      position: relative;
      text-align: center;
      .transition(all 0.3s linear 0s);
      width: 100%;
      z-index: 1;
      &:after {
        background: rgba(222, 103, 74, 0) none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: scale(0.5);
        width: 100%;
        z-index: 2;
        .transition(all 0.3s linear 0s);
      }
      img {
        .transition(all 0.3s linear 0s);
        width: 100%;
        margin: 0;
      }
      a {
        border: 1px solid @white;
        height: 48px;
        left: 50%;
        margin-left: -24px;
        margin-top: -24px;
        position: absolute;
        top: 50%;
        .transform(scale(0));
        .transition(all 0.3s linear 0s);
        width: 48px;
        z-index: 3;
        i {
          color: @white;
          padding: 16px 0 !important;
          .transition(all 0.3s linear 0s);
        }
        &:hover {
          border-radius: 50%;
          i {
            .animation(2s linear 0s normal none infinite running rotate-icon);
          }
        }
      }
      &:hover {
        a {
          .transform(scale(1));
        }
        img {
          .transform(scale(1.1));
        }
        &:after {
          background: rgba(51, 72, 120, 0.8) none repeat scroll 0 0;
          .transform(scale(1));
        }
      }
    }
    .tz-content-event-item {
      background: rgb(246, 246, 246) none repeat scroll 0 0;
      text-align: center;
      padding: 0 15px 35px;
      h6 {
        span a {
          color: rgb(68, 68, 68);
        }
        font-size: 16px;
        line-height: 19.2px;
        margin: 0;
        padding: 25px 0 8px;
      }
      strong {
        color: rgb(153, 153, 153);
        display: block;
        font-size: 12px;
        font-weight: 400;
        padding: 0;
      }
      p {
        color: #999999;
        line-height: 25.2px;
        margin: 0;
        padding: 15px 0 0;
      }
      i {
        padding-top: 10px;
        font-size: 12px;
        display: block;
        color: #999999;
      }
    }
  }
  .owl-prev, .owl-next {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    i {
      font-size: 30px;
      color: #eaeaea;
      padding: 5px 15px;
      border: 1px solid #eaeaea;
      .transition(all 0.3s linear 0s);
    }
    &:hover {
      i {
        background: #eaeaea;
        color: @color-4;
      }
    }
  }
  .owl-prev {
    left: -15%;
  }
  .owl-next {
    right: -15%;
  }
}

/*--------- Testimonial ----------*/
.parallax-style-4 {
  background-attachment: fixed !important;
  background-position: 30% 10px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  &:after {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .tz-overlay {
    padding: 53px 0 80px;
    position: relative;
    z-index: 9999;
    text-align: center;
    color: @white;
    h4 {
      font-size: 28px;
      font-weight: 400;
      line-height: 33.6px;
      margin: 0;
      padding-top: 34px;
    }
    strong {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      padding-top: 30px;
    }
  }
}

/*-------- Latest Course ----------*/
.tz-hidden {
  display: none;
}

#tz-sidemenu {
  margin: 0 -30px 0 0;
  padding: 0;
  li {
    border: 1px solid #dddddd;
    border-bottom: none;
    .transition(all 0.3s linear 0s);
    &:last-child {
      border-bottom: 1px solid #dddddd;
    }
    > div {
      overflow: hidden;
      position: relative;
      &:after {
        background: rgba(238, 238, 238, 0) none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        .transition(all 0.3s linear 0s);
        width: 100%;
      }
      &:hover {
        &:after {
          background: rgba(238, 238, 238, 1) none repeat scroll 0 0;
          left: 0;
        }
      }
    }
    &:hover, a.tz-open {
      i.tz-icon-2 {
        .animation(2s linear 0s normal none infinite running rotate-icon);
        color: #334878;
        opacity: 1;
      }
      i.tz-icon-1 {
        opacity: 0;
      }
    }

    a {
      background: rgba(238, 238, 238, 0) none repeat scroll 0 0;
      display: inline-block;
      outline: medium none;
      padding: 21px 30px;
      position: relative;
      text-decoration: none;
      .transition(all 0.3s linear 0s);
      width: 100%;
      z-index: 9999;
      i.tz-icon-1 {
        font-size: 12px;
        color: @color-4;
      }
      i.tz-icon-2 {
        color: @color-4;
        left: 30px;
        opacity: 0;
        position: absolute;
        top: 25px;
        z-index: 9;
      }
      strong {
        color: rgb(68, 68, 68);
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        padding-left: 25px;
      }
      &.tz-open {
        background: rgba(238, 238, 238, 1);
      }
    }
  }
}

.tz-tab-img {
  img {
    width: 100%;
  }
}

.tz-tab-description {
  background: #334878;
  padding: 35px 30px 30px;
  text-align: center;
  color: @white;
  //  border-bottom: 1px solid #334878;
  span {
    padding: 25px 0 0 0;
    display: block;
    a {
      border: 1px solid @white;
      color: @white;
      margin-left: 7px;
      padding: 7px 20px;
      .transition(all 0.3s linear 0s);
      display: inline-block;
      position: relative;
      &:hover {
        background: @white;
        color: #334878;
      }
    }
  }
  h4 {
    a {
      color: @white;
    }
    font-size: 18px;
    line-height: 32.4px;
    margin: 9px 0 0;
    padding: 0 0 12px;
  }
}

/*-------- Latest Article ---------*/
.carousel {
  margin: 0 -15px;
  .owl-item {
    border: none;
    .slide {
      padding: 0;
      .tz_carousel_default {
        overflow: hidden;
        .tz-article-content {
          padding: 15px;
        }
      }
      .tz_carousel_image img {
        float: none;
        margin: 0;
        padding: 0;
        .transition(all 0.5s linear 0s);
        position: relative;
      }
      .tz-article-content {
        background: rgba(51, 72, 120, 0.9) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: -200px;
        width: 100%;
        z-index: 9;
        .transition(all 0.3s linear 0s);
        h4 {
          color: @white;
          font-size: 16px;
          font-weight: 400;
          line-height: 28.8px;
          margin: 0;
          position: absolute;
          text-align: left;
          top: 35px;
          .transform(translateY(-200px));
          .transition(all 0.4s ease-in-out 0.1s);
          a {
            color: @white;
            font-weight: 400;
            font-size: 16px;
          }
        }
        p {
          color: @white;
          font-size: 13px;
          line-height: 23.4px;
          position: absolute;
          text-align: left;
          top: 95px;
          .transform(translateY(-200px));
          .transition(all 0.2s ease-in-out 0.1s);
        }
        span {
          bottom: 35px;
          display: block;
          left: 50%;
          margin-left: -85px;
          position: absolute;
          text-align: center;
          a {
            border: 1px solid @white;
            color: rgb(255, 255, 255);
            display: inline-block;
            font-size: 14px;
            line-height: 25.2px;
            padding: 10px 25px;
            position: relative;
            .transform(translateY(-200px));
            .transition(all 0.2s ease-in-out 0.3s);
            text-transform: uppercase;
            i {
              padding-right: 20px;
            }
            &:after {
              background: @white none repeat scroll 0 0;
              content: "";
              height: 100%;
              left: 50%;
              opacity: 1;
              position: absolute;
              top: 50%;
              .transform(translateX(-50%) translateY(-50%));
              .transition(all 0.3s ease 0s);
              width: 0;
              z-index: -1;
            }
            &:hover {
              &:after {
                color: #334878;
                width: 100%;
              }
              border: 0 none;
              color: #334878;
            }
          }
        }
      }
    }
    &:hover {
      .tz-video, .tz-slide {
        .transform(scale(0) rotate(45deg));
      }
      .tz_carousel_image img {
        .transform(scale(1.4));
      }
      .tz-article-content {
        .animation(0.9s linear 0s normal none 1 running bounceY);
        cursor: e-resize !important;
        opacity: 1;
        top: 0;
        .transition-delay(0s);
        background: rgba(222, 87, 73, 0.8);
        h4 {
          .transform(translateY(0px));
          .transition-delay(0.4s);
        }
        p {
          .transform(translateY(0px));
          .transition-delay(0.2s);
        }
        span a {
          .transform(translateY(0px));
          .transition-delay(0s);
        }
      }
    }
  }
}

#home_content_portfolio {
  .tz-portfolio-title {
    text-align: center;
    margin: 0;
    padding: 0 0 60px;
    color: rgb(68, 68, 68);
    font-size: 20px;
    line-height: 24px;
    i {
      display: block;
      font-size: 12px;
      margin: 0 0 10px;
    }
  }
  .TzInner {
    overflow: hidden;
    .tz-image-portfolio {
      height: 100%;
      img {
        position: relative;
      }
    }
    .tz-portfolio-content {
      height: 100%;
      left: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      text-align: left;
      top: 0;
      transition: all 0.3s ease-in 0s;
      width: 100%;
      h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 28.6px;
        margin: 50px 0 0;
        opacity: 0;
        padding: 0 25px;
        left: -100px;
        .transition(all 0.5s ease 0s);
        position: relative;
        a {
          color: @white;
        }
      }
      p {
        color: @white;
        font-size: 13px;
        line-height: 23.4px;
        margin: 0;
        opacity: 0;
        padding: 12px 25px;
        left: 100px;
        .transition(all 0.5s ease 0s);
        position: relative;

      }
      span {
        bottom: 0;
        display: block;
        left: 50%;
        margin: 0 -80px;
        opacity: 0;
        position: absolute;
        text-align: center;
        .transition(all 0.3s ease-in-out 0.1s);
        a {
          border: 1px solid @white;
          color: @white;
          display: inline-block;
          line-height: 25.2px;
          padding: 5px 20px 5px 5px;
          .transition(all 0.3s linear 0s);
          i {
            color: @white;
            font-size: 14px;
            padding: 10px 15px;
          }
          &:hover {
            i {
              .animation(2s linear 0s normal none infinite running rotate-icon);
            }
          }
        }
      }
    }
    &:hover {
      .tz-video, .tz-slide {
        .transform(scale(0) rotate(45deg));
      }
      .tz-portfolio-content {
        opacity: 1;
        background: rgba(51, 72, 120, 0.8);
        h4 {
          opacity: 1;
          left: 0;
        }
        p {
          opacity: 1;
          left: 0;
        }
        span {
          bottom: 55px;
          opacity: 1;
          transition-delay: 0.3s;

        }
      }
    }
  }
}

/*-------- Service ---------*/
.style-home-7 {
  .services.home-3 {
    margin: 0;
    > div {
      padding: 0;
    }
    .service {
      padding: 51px 0;
    }
  }
}

.style-home-6 {
  .home-4.services {
    margin: 0;
    > div {
      padding: 0;
    }
    .service {
      padding: 51px 0;
    }
  }
}

.home-4 {
  &.services {
    padding: 0;
    margin: -7px;
    > div {
      padding: 7px;
    }
    .service {
      padding: 60px 0;
      height: auto;
      border-radius: 0;
    }
  }
}

.home-3 {
  &.services {
    padding: 0;
    margin: 25px -5px 40px;
    > div {
      padding: 0 5px;
    }
    .service {
      border-radius: 0;
      margin: 0;
      padding: 40px 0;
      height: auto;
    }
  }
}

.tz-services {
  text-align: center;
}

.services2 {
  background: rgb(246, 246, 246);
  padding: 35px 55px;
  margin: 0;
  .custom_text {
    text-align: left;
    img {
      margin: 5px 0 0 0;
    }
    h3 {
      margin: 0;
      padding: 50px 0 0 0;
      a {
        color: @color-4;
        font-size: 24px;
        line-height: 28.8px;
      }
    }
    p {
      color: #999999;
      line-height: 25.2px;
      padding: 31px 30px 0 0;
    }
    .custom_button {
      margin: 65px 0 0 0;
      display: block;
      span {
        border: 1px solid rgb(68, 68, 68);
        border-radius: 3px;
        color: rgb(68, 68, 68);
        display: inline-block;
        padding: 11px 25px;
        i {
          padding-right: 20px;
        }
      }
    }
  }
  .service {
    padding: 50px 0;
    border-radius: 50%;
    height: 185px;
    text-align: center;
    margin-bottom: 20px;
    .icon_service {
      background: @white;
      border-radius: 50%;
      display: block;
      height: 45px;
      transition: all 0.3s linear 0s;
      width: 45px;
      text-align: center;
      margin: 0 auto 10px auto;
      i {
        font-size: 20px;
        padding: 12px 0;
      }
    }
    .more-btn {
      color: @white;
      display: inline-block;
      font-size: 16px;
      line-height: 19.2px;
      padding-top: 3px;
    }
    &:hover {
      .icon_service i {
        -webkit-animation: rotate-icon 2s infinite linear;
        -moz-animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;
        -ms-animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;
        -o-animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;
        animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;

      }
    }
  }
}

.services {
  margin: 12px -15px 55px -15px;
  padding: 40px 0;
  .service {
    padding: 85px 0;
    border-radius: 50%;
    height: 270px;
    text-align: center;
    .icon_service {
      background: @white;
      border-radius: 50%;
      display: block;
      height: 60px;
      transition: all 0.3s linear 0s;
      width: 60px;
      text-align: center;
      margin: 2px auto 10px auto;
      i {
        font-size: 28px;
        padding: 16px 0;
      }
    }
    .more-btn {
      color: @white;
      display: inline-block;
      font-size: 16px;
      line-height: 19.2px;
      padding-top: 3px;
    }
    &:hover {
      .icon_service i {
        -webkit-animation: rotate-icon 2s infinite linear;
        -moz-animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;
        -ms-animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;
        -o-animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;
        animation: rotate-icon 2s infinite linear, scale-icon 0.3s 1 linear;

      }
    }
  }
}

.tz-item-more-details {
  display: inline-block;
  position: relative;
  overflow: hidden;
  span {
    border: 1px solid @color-4;
    color: @color-4;
    display: inline-block;
    line-height: 25.2px;
    padding: 10px 25px;
    border-radius: 3px;
    position: relative;
    z-index: 99;
    i {
      padding-right: 25px;
    }
  }
  &:after {
    border-radius: 2px;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    .transition(all 0.3s linear 0s);
    .transform(rotate(45deg));
  }
  &:hover {
    &:after {
      .transform(scale(8));
      background: rgba(0, 0, 0, 1);
    }
    span {
      color: @white;
    }
  }
}

.tz-services-wrapper {
  p {
    color: #999999;
    line-height: 25.2px;
    margin: 0 0 30px;
    padding: 27px 0 0;
  }
  h3 {
    font-size: 24px;
    line-height: 28.8px;
    margin: -5px 0 0;
  }
}

/*-------- Mail ----------*/
.acymailing_module_form {
  background: transparent;
  position: relative;
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  .acymailing_introtext {
    float: left;
    width: 28%;
    padding: 25px 18px !important;
    background: transparent;
    text-align: left;
    color: @color-4;
    font-size: 13px;
  }

}

div.acymailing_module {
  .acymailing_form {
    float: left;
    width: 72%;
    height: 100%;
    position: absolute;
    right: 0;
    padding-left: 30px !important;
    .ds-table-cell {
      text-align: left;
    }
    .acyfield_name, .acyfield_email, .acysubbuttons {
      display: inline-block;
      input {
        top: 0;
        border-radius: 2px;
      }
    }
    .acyfield_name, .acyfield_email {
      margin-right: 10px !important;
      input {
        min-width: 300px;
      }
    }
  }
  .acysubbuttons {
    &::before {
      position: relative;
      left: 35px;
      top: 1px;
      content: '\f0b2';
      font-size: 14px;
      font-family: FontAwesome;
      color: @color-4;
      z-index: 3;
    }
  }
}

.style-home-3 {
  .acymailing_module_form .acymailing_introtext {
    color: @white;
    padding: 33px 18px !important;
  }
  .acymailing_form {
    .acyfield_name, .acyfield_email, .acysubbuttons {
      input {
        border-radius: 0;
      }
    }
    .button_submit {
      border-color: @white;
      color: @white;
      &:hover {
        background: transparent;
      }
    }
    .acysubbuttons::after {
      color: @white;
    }
  }
}

.style-home-1 {
  height: 0;
  position: relative;
  top: -47px;
  z-index: 9999;
  .acymailing_module_form {
    background: @white;
  }
  .acymailing_introtext {
    background: #334878;
    color: @white;
  }
}

.acymailing_form {
  text-align: center;
  input {
    background: rgb(247, 247, 247) none repeat scroll 0 0;
    border: 1px solid #eeeeee;
    color: #aaaaaa;
    font-size: 14px;
    line-height: 25.2px;
    margin: 0;
    padding: 10px 15px 10px 20px;
    .transition(all 0.3s linear 0s);
  }
  .button_submit {
    border: 1px solid rgb(69, 69, 69);
    background: transparent;
    border-radius: 2px;
    color: rgb(84, 84, 84);
    display: inline-block;
    padding: 10px 25px 10px 50px;
    .transition(all 0.3s linear 0s);
    width: auto;
    text-transform: uppercase;
    &:hover {
      background: #eeeeee none repeat scroll 0 0;
      border-color: #ffffff;
    }
  }
  .button_submit, input {
    position: relative;
  }
  .title {
    color: rgb(68, 68, 68);
    font-size: 13px;
    line-height: 23.4px;
    margin-right: 45px;
    width: 220px;
    display: inline-block;
  }
}

/*-------- Footer ---------*/
.tz-footer-text,
.tz-footer-image {
  text-align: center;
  //  padding: 0;
}

.tz-footer-content {
  background: #334878;
  padding: 84px 0 57px;
  text-align: center;
  .container img {
    display: inline-block;
    margin: 0 0 -10px;
  }
  .tz-footer-part {
    &:after {
      display: block;
      clear: both;
      content: '';
    }
    > li {
      a {
        color: @white;
        padding: 0;
        font-size: 13px;
        line-height: 26px;
        &:hover, &:focus {
          color: @white;
          background: transparent;
        }
      }
      &.open {
        a {
          color: @white;
          background: transparent;
        }
      }
    }
  }
  .title_mod_menu {
    h4 {
      color: rgb(255, 255, 255);
      font-size: 16px;
      line-height: 19.2px;
      padding: 15px 0 20px;
      margin: -4px 0;
      text-transform: uppercase;
    }
    i {
      color: @white;
      font-size: 12px;
    }
  }
}

.tz-footer-content .tz-footer-part
.tz-footer-content .tz-footer-content-main img {
  margin: 5px 0;
}

.tz-footer-address-site {
  padding: 40px 0 65px;
  text-align: center;
}

.tz-footer-text address {
  color: rgb(68, 68, 68);
  font-size: 13px;
  line-height: 19.4px;
  margin: 0;
}

.tz-footer-content-2 .tz-footer-content-main ul li a {
  color: @white;
}

.tz-footer-content-6 .tz-footer-content-main h4,
.tz-footer-content-6 .tz-footer-content-main ul li a {
  color: #333333;
}

/*--------- Revolution -------*/
.rev_slider_wrapper {
  li:after {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.button1 {
  .tz-item-more-details {
    span {
      border-radius: 0;
      border-color: @white;
      color: @white;
      i {
        color: @white;
      }
    }
    &:hover {
      span {
        border-color: rgba(255, 255, 255, 1);
      }
      &:after {
        background: rgba(255, 255, 255, 1);
      }
      span, i {
        color: @color-4;
      }
    }
  }
}

.tp-leftarrow.default {
  background: transparent !important;
  &:after {
    content: '\f104';
    position: absolute;
    font-family: FontAwesome;
    color: @white;
    font-size: 35px;
    left: 0;
  }
}

.tp-rightarrow.default {
  background: transparent !important;
  &:after {
    content: '\f105';
    position: absolute;
    font-family: FontAwesome;
    color: @white;
    font-size: 35px;
    right: 0;
  }
}

/*--------- Custom Header --------*/
.tz-sign-in {
  text-align: center;
  color: @white;
  padding-bottom: 16px;
  padding-top: 11px;
  line-height: 16.8px;
  &.text-left {
    text-align: left;
    .tz-search {
      background: transparent;
      padding: 3px 0 0 0;
      border: none;
      margin: 0;
      float: right;
      i {
        padding: 0;
      }
    }
  }
  i {
    padding-right: 30px;
    padding-left: 5px;
  }
}

.tz-liner-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #334878;
  height: 7px;
  text-align: center;
  z-index: 999;
  img {
    z-index: 9999;
    position: relative;
  }
}

/*--------- Header - 6 ---------*/
.tz-header-6 {
  width: 100%;
  #tzlogo {
    border: medium none;
    margin-right: 0;
    margin-top: -8px;
    padding: 0;
    position: relative;
    .transition(all 0.3s linear 0s);
    display: block;
  }
  .tz-search {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin: 35px 0 0;
    padding: 17px 35px;
    .transition(all 0.3s linear 0s);
    border-top: none;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    background: transparent;
    i {
      color: #555555;
    }
  }
  .navbar-inner {
    .nav-collapse {
      padding: 0;
    }
    .plazart-megamenu {
      .level0 {
        > li {
          padding: 36px 0;
          transition: all 0.3s linear 0s;
          > a {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            color: #555555;
            display: inline-block;
            line-height: 16.8px;
            padding: 19px 27px 20px;
            position: relative;
            background: transparent;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  #plazart-mainnav {
    .tz-search, .navbar-inner {
      float: right;
    }
  }
  &.tz-header-6-eff {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    #plazart-mainnav .level0 > li {
      padding: 10px 0;
    }
    .tz-search {
      margin-top: 10px;
    }
    #tzlogo {
      position: absolute;
      left: 15px;
      top: 0;
    }
  }
}

/*--------- Header 7---------*/
.tz-header-7 {
  position: fixed;
  width: 100%;
  top: 9px;
  left: 0;
  z-index: 99999;
  .transition(all 0.3s linear 0s);
  &.tz-header-7-eff {
    background: #334878;
    top: 0;
  }
  #tzlogo {
    display: block;
    padding: 16px 20px 16px 40px;
    //    border-right: 2px solid @white;
  }
  #plazart-mainnav {
    .level0 {
      > li {
        > a {
          padding: 37px 36px;
          color: @white;
          text-transform: uppercase;
          line-height: 16.8px;
        }
        &:hover,
        &.open {
          > a {
            background: rgba(51, 72, 120, 0.8);
          }
        }

      }
    }
  }
  .navbar-inner {
    padding-right: 90px;
    .nav-collapse {
      border-left: 2px solid @white;
      border-right: 2px solid @white;
      padding: 0;
    }
  }
  .tz-search {
    border: medium none;
    margin: 0;
    padding: 35px 38px 36px;
    float: right;
    i {
      color: @white;
    }
    &:hover {
      background: #334878;
      i {
        color: @white;
      }
    }
  }
}

/*---------  Header 3 -------*/
.tz-header-3 {
  position: fixed;
  top: 44px;
  width: 100%;
  background: transparent;
  z-index: 99999;
  .transition(all 0.3s linear 0s);
  &.tz-header-3-eff {
    top: 0;
    background: #334878;
    #plazart-mainnav .level0 > li {
      padding: 18px 0;
    }
    #plazart-mainnav {
      .tz-search {
        margin-top: 15px;
      }
      .dropdown-menu {
        border-color: #2d938e;
      }
    }
    #tzlogo {
      img {
        width: 90px;
        position: absolute;
        top: 15px;
        left: 0;
      }
    }
  }
  #plazart-mainnav {
    .tz-search {
      float: right;
    }
    .navbar-inner {
      padding-right: 90px;
    }
    .level0 {
      > li {
        padding: 33px 0 34px;
        > a {
          padding: 18px 34px;
          color: @white;
          text-transform: uppercase;
          background: transparent;
          line-height: 1em;
        }
        &:hover,
        &.open {
          > a {
            background: transparent;
          }
        }

      }
    }
  }
  .tz-search {
    border: medium none;
    margin: 30px 0 0;
    color: @white;
    padding: 16px 28px 16px;
  }
  #tzlogo {
    position: relative;
    img {
      .transition(all 0.3s linear 0s);
    }
  }
}

.tz-custom-header-3 {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  .transition(all 0.3s linear 0s);
  &.not-fixed {
    visibility: hidden;
  }
}

/*------- Header 5 -------*/
.tz-header-5 {
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 99999;
  .transition(all 0.3s linear 0s);
  &.tz-header-5-eff {
    background: #334878;
    #tzlogo {
      img {
        background: #334878;
        padding: 18px 44px;
      }
    }
    .menu1 {
      background: #334878;
    }
    #plazart-mainnav {
      .level0 > li {
        padding: 20px 0 20px;
        > a {
          padding: 15px 29px;
        }
      }
    }
    .tz-search {
      margin-top: 15px;
    }
  }
  .menu1 {
    background: rgb(51, 72, 120);
    .transition(all 0.3s linear 0s);
  }

  #plazart-mainnav {
    .tz-search {
      float: right;
    }
    .navbar-inner {
      padding-right: 85px;
    }
    .level0 {
      > li {
        padding: 27px 0 30px;
        > a {
          padding: 16px 29px;
          color: @white;
          text-transform: uppercase;
          background: transparent;
          line-height: 1em;
          border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
        &:hover,
        &.open {
          > a {
            background: transparent;
          }
        }

      }
    }
  }
  .tz-search {
    border: medium none;
    margin: 20px 0 0;
    color: @white;
    padding: 19px 35px;
    .transition(all 0.3s linear 0s);
  }
  #tzlogo {
    img {
      background: rgb(232, 190, 108);
      padding: 24px 44px 31px;
      .transition(all 0.3s linear 0s);
    }
  }
}

/*-------- Header 2 -------*/
.tz-header-2 {
  &.tz-header-2-eff {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999999;
    #tzlogo {
      padding: 10px 0;
      img {
        width: 150px;
      }
    }
    #plazart-mainnav {
      .level0 > li {
        padding: 25px 0;
      }
    }
  }
  .tz-search {
    display: none;
  }
  #plazart-mainnav {
    .navbar-inner {
      .plazart-megamenu {
        float: right;
        padding: 0;
      }
    }
    .level0 {
      > li {
        padding: 40px 0;
        .transition(all 0.4s linear 0s);
        &:first-child {
          > a {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
        > a {
          padding: 18px 32px;
          color: @white;
          text-transform: uppercase;
          background: transparent;
          line-height: 1em;
          border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
        &:hover,
        &.open {
          > a {
            background: transparent;
          }
        }

      }
    }
  }
  #tzlogo {
    padding: 25px 0 34px;
    .transition(all 0.4s linear 0s);
    display: block;
  }
}

/*-------- Header 1 ----------*/
.tz-header-home-1 {
  height: 7px;
  .transition(all 0.4s linear 0s);
  > .container > .row {
    position: relative;
  }
  .menu {
    position: absolute;
    width: 100%;
    top: -60px;
    z-index: 999;
  }
  #plazart-mainnav {
    .level0 {
      > li {
        > a {
          background: #334878;
          border: 0 none;
          color: #ffffff;
          margin-right: 2px;
          padding: 20px 27px;
          text-transform: uppercase;
        }
        &.open, &:focus, &:hover {
          > a {
            background: @white;
            color: @color-4;
          }
        }
      }
    }
  }
  .tz-search {
    background: #334878;
    border: 0 none;
    bottom: 0;
    display: block;
    margin: 0 2px 0 0;
    padding: 0;
    position: relative;
    float: right;
    right: 0;
    top: 0;
    i {
      padding: 23px 27px;
      color: @white;
    }
    &:hover {
      background: @white;
      i {
        color: @color-4;
      }
    }
  }
  &.affix-eff {
    background: #334878;
    height: auto;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    .tz-menu-6 {
      padding-left: 0;
      #plazart-mainnav {
        .level0 > li:first-child > a {
          padding-left: 15px;
        }
      }
    }
  }
}

/*-------- Header 4 ---------*/
.tz-button-toggle.tz-canvas-customer {
  background: transparent;
  border: medium none;
  display: block;
  float: left;
  margin: 32px 17px 0;
  padding: 0;
  .transition(all 0.3s linear 0s);
  i {
    color: @white;
  }
}

.tz-header-4 {
  .logo {
    position: relative;
    #tzlogo {
      img {
        display: block;
        left: 50%;
        margin-left: -60px;
        position: absolute;
        top: 8px;
        z-index: 9999;
        width: 120px;
        max-width: 120px;
        .transition(all 0.3s linear 0s);
      }
    }
  }

  .menu-home-1 {
    > li {
      margin: 0;
      display: block !important;
      > a {
        background: transparent;
        color: rgb(255, 255, 255);
        font-size: 14px;
        line-height: 16.8px;
        padding: 34px 32px;
        .border-radius(0);
        .transition(all 0.3s linear 0s);
        text-transform: uppercase;
        &:focus {
          background: transparent;
        }
        .caret {
          display: none;
        }
      }
      &.open {
        .dropdown-menu {
          top: 100%;
          visibility: visible;
        }
      }
      &.open, &:hover, &:focus {
        a {
          background: transparent;
        }
      }
      .dropdown-menu {
        margin: 0;
        padding: 0;
        .border-radius(0);
        border: none;
        top: 150%;
        display: block;
        visibility: hidden;
        .transition(all 0.4s ease 0s);
        .box-shadow(none);

        > li {
          > a {
            line-height: 1.5em;
            padding: 11px 80px 11px 30px;
            .transition(none);
            .box-shadow(none);
            border: none;
          }
          &.active, &:hover {
            > a {
              background: #334878;
              color: @white;
              border-color: #334878;
            }
          }
        }
      }
    }
  }
  .tz-search {
    background: transparent;
    .border-radius(0);
    border: none;
    top: 32px;
    position: relative;
    padding: 0;
    right: 15px;
    .transition(all 0.3s linear 0s);
    i {
      color: @white;
    }
  }
  &.tz-header-4-eff {
    position: fixed;
    width: 100%;
    z-index: 999999;
    .menu-home-1 {
      > li > a {
        padding: 25px 32px;
      }
    }
    .logo #tzlogo {
      img {
        max-width: 90px;
        margin-left: -45px;
        top: 0;
        width: 90px;
      }
    }
    .tz-button-toggle.tz-canvas-customer {
      margin-top: 22px;
    }
    .tz-search {
      top: 22px;
    }
  }
}

/*--------- Header 1 -----------*/
.tz-header-1 {
  #tzlogo {
    display: block;
    padding: 12px 0 12px 56px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
  .tz-search {
    float: right;
    i {
      color: @white;
      padding: 21px 30px;
    }
    &:hover {
      i {
        background: @white;
        color: @color-4;
      }
    }
  }
  .navbar-inner {
    padding-right: 75px;
  }
  #plazart-mainnav {
    .level0 {
      > li {
        &:first-child {
          > a {
            border-left: 1px solid rgba(255, 255, 255, 0.3);
          }
          .dropdown-menu {
            left: 0;
          }
        }
        > a {
          border-right: 1px solid rgba(255, 255, 255, 0.3);
          color: @white;
          display: inline-block;
          line-height: 16.8px;
          padding: 19px 27px 20px;
          position: relative;
          text-transform: uppercase;
          .transition(all 0.3s linear 0s);
        }
        &:hover,
        &.open {
          > a {
            background: @white;
            color: @color-4;
          }
        }
      }
    }
    .dropdown-menu {
      border-color: transparent;
      border: none;
    }
  }
  &.tz-header-eff {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999999;
  }
}

/*------ Menu ------*/
#menu ul li {
  min-width: 0 !important;
}

.tz-canvas-padding {
  padding: 0;
}

.canvas-menu {
  background-color: @white;
  bottom: 0;
  left: -20em;
  position: fixed;
  top: 0;
  width: 20em;
  z-index: 9999999;
}

.tz-offcanvas-close {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0 none;
  border-radius: 50%;
  color: #fff;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s linear 0s;
  width: 30px;
  z-index: 9999999;
  i {
    color: #DDDDDD;
    font-size: 20px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
}

.tzscrollable {
  opacity: 1;
  overflow: auto;
  position: relative;
  top: 74px;
  .transition(all 0.3s linear 0.2s);
  > li {
    border-top: 1px solid #eeeeee;
    margin: 0 !important;
    padding: 20px;
    &:last-child {
      border-bottom: 1px solid #eeeeee;
    }
    &.open {
      > a {
        background: transparent;
        &:hover, &:active, &:focus {
          background: transparent;
        }
      }
    }
    > a {
      color: @color-4;
      background: transparent;
      padding: 0;
      font-weight: 400;
      line-height: 16.8px;
      z-index: 99993;
      &:hover, &:active, &:focus {
        background: transparent;
      }
      i {
        padding-right: 20px;
      }
      .tzicon-2 {
        color: #95001d;
        left: 0;
        position: absolute;
        top: 0;
        .transform(scale(0));
        .transition(all 0.3s linear 0s);
      }
      .tzicon-1 {
        opacity: 1;
        .transition(all 0.3s linear 0s);
      }
    }
    &:after {
      background: rgba(238, 238, 238, 0) none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.3s linear 0s;
      width: 0;
    }
    &:hover {
      &:after {
        background: #eeeeee none repeat scroll 0 0;
        width: 100%;
      }
      .tzicon-1 {
        opacity: 0;
      }
      .tzicon-2 {
        .transform(scale(1));
      }
    }
  }
}

.full {
  img {
    width: 100%;
  }
}

#plazart-mainnav {
  margin: 0;
  &.navbar {
    border: none;
    .navbar-inner {
      .nav-collapse {
        padding: 0;
      }
    }
  }
  .caret {
    display: none;
  }
  .level0 > li {
    .transition(all 0.3s linear 0s);
    display: block !important;
  }
  //  .open > .dropdown-menu,
  .dropdown-menu {
    .transition(all 0.3s linear 0s);
    .box-shadow(none);
    padding: 0;
    border: none;
    li {
      > a {
        color: #666666;
        padding: 11px 30px;
        line-height: 1.5em;
      }
      &.current, &:hover, &.opacity {
        > a {
          background: #334878;
          color: @white;
        }
      }
    ;
    }
  }
  .style1 {
    .mega-inner, .mega-col-nav {
      padding: 0;
    }
  }
  .style2 {
    position: static !important;
    .mega-inner {
      padding: 20px 0;
      .mega-nav {
        border-right: 1px solid #eeeeee;
        li > a {
          padding: 11px 20px;
          i {
            padding-right: 15px;
            margin: 0;
          }
        }
      }
    }
    .mega-col-nav {
      padding: 0;
      &:last-child {
        .mega-inner {
          .mega-nav {
            border-right: none;
          }
        }
      }
    }

  }
  .level0 > li:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
  }
}

.search-fixed {
  position: fixed;
  top: 0;
  z-index: 9999999999;
}

.tz-search-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000000;
  z-index: 9999999999;
  .transform(scale(0));
  .transition(all 0.3s linear 0s);
  &.tz-content-search-eff {
    .transform(scale(1));
  }
  i.tz-form-close {
    color: rgb(255, 255, 255);
    font-size: 50px;
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
  }
  .search-query {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    font-size: 40px;
    height: 20%;
    left: 15%;
    padding: 10px 30px;
    position: absolute;
    top: 40%;
    width: 75%;
    z-index: 999999;
  }
}

/*----- Search result ----*/
.search-results {
  .result-title {
    a {
      color: @color-4;
    }
  }
  .result-created {
    padding: 0 0 15px;
    color: #bababa;
  }
  .result-category {
    span {
      color: #bababa;
    }
  }
  .result-text {
    padding: 10px 0;
    color: #bababa;
  }
}

/*----- Breadcrumbs ------*/
.tz_breadcrumb {
  text-align: center;
  h1 {
    margin: 5px 0 0;
    font-size: 30px;
  }
}

.tz-ethic-breadcrumbs {
  text-align: center;
  padding: 0;
  li {
    display: inline-block;
  }
  h2 {
    margin: 0 4px 0 0;
    a, span {
      color: #aaaaaa;
      font-size: 13px;
      line-height: 1.5em;
      text-transform: uppercase;
    }
  }
  .bread-active {
    color: #aaaaaa;
    font-size: 13px;
    line-height: 1.5em;
    text-transform: uppercase;
  }
}

/*--------- Jevent ------*/
#jevents_body {
  background: transparent;
  border: none;
  padding: 0;
  #jev_maincal {
    background: transparent;
    border: none;
    margin: 0;
    .jev_listrow {
      border: none;
      float: none;
      margin: 0;
      background: transparent;
    }
    .ev_ul {
      //      margin: 0;
      li {
        margin: 0;
        border: none;
      }
    }
  }
}

.tz-events-item {
  border-bottom: 1px solid #eeeeee;
  margin: 0 0 31px;
  text-align: center;
}

.tz-events-item .tz-image {
  overflow: hidden;
  position: relative;
}

.tz-events-item .tz-image img {
  width: 100%;
}

.tz-events-item .tz-image::after {
  background: rgba(149, 0, 29, 0) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  .transition(all 0.3s linear 0s);
  width: 100%;
}

.tz-events-item .tz-image:hover::after {
  background: rgba(51, 72, 120, 0.6) none repeat scroll 0 0;
}

.tz-events-item .tz-image a {
  background: #444444 none repeat scroll 0 0;
  display: inline-block;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  top: 50%;
  .transform(scale(0));
  .transition(all 0.3s linear 0s);
  z-index: 9999;
  i {
    padding: 13px 11px 13px 13px;
  }
}

.tz-events-item .tz-image a:hover {
  border-radius: 50%;
}

.tz-events-item .tz-image:hover a {
  transform: scale(1);
}

.tz-events-item .tz-image a i {
  color: #ffffff;
}

.tz-events-item .tz-image a:hover i {
  animation: 2s linear 0s normal none infinite running rotate-icon, 0.3s linear 0s normal none 1 running scale-icon;
}

.tz-events-item .tz-description {
  .tz_ethic_cross {
    padding: 28px 0 15px;
    display: block;
  }
  img {
    display: inline-block;
    padding: 28px 0 15px;
  }
}

.tz-events-item .tz-description h3 {
  margin: 0 0 10px;
}

.tz-events-item .tz-description h3 a {
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.tz-events-item .tz-description strong {
  color: #444444;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  line-height: 23.4px;
  padding: 0 0 49px;
}

/*------- Event List ----------*/
.jalternative {
  ul.ev_ul {
    margin: 0 !important;
    li {
      border: none;
      border-bottom: 1px solid #eeeeee;
      margin: 0 0 32px !important;
      padding: 0 0 37px !important;
      .tz-images {
        img {
          width: 100%;
        }
      }
      .tz-content {
        h3 {
          margin: 0;
          padding: 13px 0 22px;
          i {
            font-size: 12px;
            padding-right: 15px;
          }
          a {
            color: #444444;
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
          }
        }
        .date {
          color: #95001d;
          display: block;
          font-size: 13px;
          font-weight: 400;
          line-height: 23.4px;
          padding: 0 0 25px;
          i {
            color: @color-4;
            padding-right: 20px;
          }
        }
        p {
          color: #999999;
          line-height: 25.2px;
          margin: 0;
        }
        span.button-readmore {
          display: inline-block;
          margin: 43px 0 0;
          overflow: hidden;
          position: relative;
          a {
            border: 1px solid @color-4;
            color: @color-4;
            display: inline-block;
            font-size: 14px;
            line-height: 25.2px;
            padding: 10px 15px;
            position: relative;
            z-index: 999;
            i {
              font-size: 14px;
              padding-right: 15px;
            }
          }
          &:after {
            background: rgba(147, 0, 33, 1) none repeat scroll 0 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            .transition(all 0.3s linear 0s);
            width: 0;
          }
          &:hover {
            a {
              color: @white;
            }
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/*---------- Event Detail ----*/
.jev_eventdetails_body {
  > div {
    padding: 0 !important;
  }
  br {
    display: none;
  }
  .jev_evdt_summary {
    background: #f6f6f6;
    width: 100%;
    > form {
      padding: 0 15px 15px;
    }
    > img {
      width: 100%;
    }
  }
  .jev_evdt_title {
    font-size: inherit;
    padding: 0;
    h3 {
      color: #444444;
      font-size: 22px;
      font-weight: 400;
      line-height: 26.4px;
      margin: 0;
      padding: 13px 0 16px;
      i {
        padding-right: 10px;
        font-size: 12px;
      }
    }
  }
  .date {
    color: #334878;
    padding-bottom: 30px;
    background: transparent;
    i {
      font-size: 12px;
      padding-right: 15px;
      color: @color-4;
    }
  }
  .jev_evdt_desc {
    p {
      color: #999999;
      line-height: 25.2px;
      margin: 0;
      padding: 0 0 25px;
    }
  }
  .share {
    > div {
      float: left;
    }
    .facebook {
      height: 30px;
      padding-left: 20px;
      overflow: hidden;
      iframe {
        height: 30px;
      }
    }
  }
  .tz-day-open {
    padding-bottom: 40px;
    border-bottom: 1px solid #eeeeee !important;
  }
}

.location_map {
  padding-top: 45px;
  text-align: center;
  .title {
    padding-bottom: 40px;
    font-size: 22px;
    line-height: 24.6px;
    color: @color-4;
    i {
      display: block;
      font-size: 12px;
      padding-bottom: 10px;
    }
  }
}

.jevattendform {
  padding: 15px;
  background: #f6f6f6;
  > h3 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
  }
  .mootabs_panel {
    background: transparent;
    margin: 0;
    padding: 0;
    .paramlist_key {
      width: 50% !important;
    }
    .paramlist
    tr {

      td {
        padding: 0 0 10px 0 !important;
        label {
          font-weight: 400;
          color: @color-4;
          margin: 0;
        }
        select {
          padding: 5px 25px 5px 5px;
          option {
            padding-left: 10px;
          }
        }
      }
    }
  }
  #jevattendsubmit {
    background: transparent;
    border-radius: 0;
    color: @color-4;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px !important;
    border-color: @color-4;
    text-transform: uppercase;
    .transition(all 0.3s linear 0s);
    margin-top: 15px !important;
    &:hover {
      background: #334878;
      color: @white;
    }
  }

}

.rsvp_attendeeslink {
  background: #f6f6f6;
  > a {
    background: transparent;
    border-radius: 0;
    color: @color-4;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px !important;
    border: 1px solid @color-4;
    text-transform: uppercase;
    .transition(all 0.3s linear 0s);
    margin: 0 15px 15px !important;
    display: inline-block;
    &:hover {
      background: #334878;
      color: @white;
    }
  }
}

/*------ RsPro ------*/
.jevattendeestable {
  tr {
    td, th {
      padding: 0 10px !important;
      border: 1px solid #EEEEEE !important;
      font-weight: normal;
    }
  }
}

.jevrsvppro {
  padding: 0 !important;
  margin: 0 !important;
  #toolbar-box {
    background: transparent;
    margin: 0;
    .btn-toolbar {
      margin: 0;
    }
  }
  h1.page-title {
    font-size: 20px;
  }
}

#attendeelist {
  tr {
    a, td {
      font-size: 12px;
      color: @color-4;
    }
  }
}

.jev_eventattendees_adminlist {

  .inputbox {
    border: 1px solid @color-4;
    border-radius: 2px;
    margin: 20px 20px 20px 0;
    padding: 5px 10px;
  }
  #attendeelist {
    tr {
      td {
        text-align: center;
      }
    }
  }
}

table.admintable {
  td {
    &.key, &.paramlist_key {
      text-align: left;
      width: 50%;
      background: transparent;
      border: none;
      padding: 10px 0;
    }
  }
}

#jevattendsubmit {
  border: 1px solid #334878;
  padding: 10px;
  text-transform: uppercase;
  color: #334878;
  background: @white;
  &:hover {
    background: #334878;
    color: @white;
  }
}

#jevattend_yes, #jevattend_no {
  margin: 0 5px;
}

/*-------- Courses ------*/
.tz_gird {
  .tz-img,
  .osg_image {
    width: 47% !important;
  }
  .tz-description,
  .osg_info {
    width: 53% !important;
  }
  .tz-description {
    padding-top: 28px !important;
  }
  .courses_portfolio,
  .seminarman .TzContent {
    margin: -15px -15px 0;
    .TzInner {
      margin: 15px 15px 0;
    }
  }
}

.tz-course-pagination {
  padding-top: 40px;
  text-align: center;
  ul {
    li {
      display: inline-block;
    }
  }
}

.seminarmancoursetable {
  .TzInner {
    overflow: hidden;
    background: #f7f7f7;
    .osg_image {
      width: 50%;
      overflow: hidden;
      height: 100%;
      img {
        position: relative;
      }
    }
    .osg_info {
      width: 50%;
      padding: 28px 0 0 20px;
      position: relative;
      height: 100%;
      overflow: hidden;
      h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 28.8px;
        margin: 0;
        display: inline-block;
        a {
          color: @color-4;
        }
      }
      .intro {
        color: #858585;
        font-size: 13px;
        line-height: 23.4px;
        margin: 0;
        padding: 20px 0 0 0;
      }
    }
    .tag {
      padding-top: 30px;
      a {
        padding: 5px 15px;
        font-size: 13px;
        line-height: 23.4px;
        background: @white;
        color: @color-4;
        margin-right: 5px;
        margin-bottom: 5px;
        display: inline-block;
        .transition(all 0.3s linear 0s);
        &:hover {
          background: @color-4;
          color: @white;
        }
      }
    }
    .button_readmore {
      position: absolute;
      bottom: 0;
      //      right: 0;
      width: 100%;
      a {
        padding: 15px 0;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        color: rgb(170, 170, 170);
        display: block;
        .transition(all 0.3s linear 0s);
        i {
          padding-right: 20px;
        }
      }
      &:hover {
        a {
          background: #f2a633;
          color: @white;
        }
      }
    }
    &.osg_left {
      .osg_info, .osg_image {
        float: left;
      }
      .button_readmore {
        left: 0;
      }
    }
    &.osg_right {
      .osg_info, .osg_image {
        float: right;
      }
      .button_readmore {
        right: 0;
      }
    }
  }

}

/*------- Detail Course -------*/
div#seminarman {
  .course_details {
    border: none;
    background: #f6f6f6;
    margin: 0;
    .course_details_info {
      padding: 20px 20px 35px;
    }
    .course_detail_image {
      img {
        width: 100%;
      }
    }
    h3 {
      margin: 0;
      color: #444444;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      padding: 0 0 25px;
      i {
        padding-right: 20px;
        font-size: 12px;
      }
    }
    .price,
    .location,
    .info1 {
      padding-bottom: 15px;
    }
  }
  h2.seminarman-title {
    color: #444444;
    font-size: 22px;
    font-weight: 400;
    line-height: 26.4px;
    margin: 0;
    padding: 13px 0 16px;
    border: none;
    i {
      padding-right: 20px;
      font-size: 12px;
    }
  }
  .tutor,
  .capacity,
  .group_level,
  .date_time, .taglist, .categorylist {
    padding: 0 0 15px;
    color: @color-1;
    i {
      padding-right: 20px;
      font-size: 12px;
      color: @color-4;
    }
    span {
      font-size: 13px;
      line-height: 23.4px;
    }
    a {
      color: @color-4;
      &:hover {
        color: @color-1;
      }
    }
  }
  div.description {
    padding: 15px 0 0;
    margin: 0;
    p {
      color: #999999;
      line-height: 25.2px;
      margin: 0;
      padding: 0 0 25px;
    }
  }
}

.osg-caption {
  font-size: 16px;
  color: @color-4;
  padding: 15px 0;
  margin: 0;
  i {
    padding-right: 15px;
    font-size: 12px;
  }
}

.course_applicationform {
  display: none;
  padding: 0 15px;
  .transition(all 0.3s linear 0s);
  .form-group {
    input {
      width: 70%;
    }
  }
  .btn-login {
    display: inline-block;
    padding: 10px 15px;
    border: 1px solid @color-4;
    .transition(all 0.3s linear 0s);
    margin-bottom: 15px;
    i {
      padding-right: 15px;
    }
    input {
      background: transparent;
      border: none;
      padding: 0;
    }
    &:hover {
      background: @color-1;
      color: @white;
      border-color: @color-1;
    }
  }
  .ccontentTable {
    label {
      font-weight: 400;
    }
  }
}

/*----- Teacher -----*/
.tutor_block {
  border: none;
  padding: 0;
  margin-bottom: 30px;
}

/*------ Tag ------*/
.tag-category {
  ul {
    li {
      h3 {
        a {
          font-size: 20px;
          color: @color-4;
        }
      }
    }
  }
}

.TzTag {
  .TzTagHeading {
    margin: 0;
  }
}

/*----- Contact -----*/
.contact {
  .page-header {
    text-align: center;
    border: none;
    padding: 40px 0 0;
    margin: 0;
    border-top: 1px solid #EEEEEE;

    h3 {
      margin: 20px 0 30px;
      span {
        text-transform: uppercase;
      }
    }
  }
  .contact-emailto {
    a {
      color: @color-4;
    }
  }
  .contact-miscinfo {
    padding: 0 30px;
    text-align: center;
    .contact-misc {
      text-align: center;
      color: #bababa;
    }
  }
  .contact-address {

    > div {
      text-align: center;
      padding: 75px;
      i {
        padding-bottom: 25px;
        display: block;
      }
    }
  }
  .icon_bg {
    height: 75px;
    width: 75px;
    background: #334878;
    border-radius: 50%;
    margin: auto auto 15px;
    &:hover {
      i {
        .transform(rotate(180deg));
      }
    }
    i {
      padding: 25px;
      font-size: 25px;
      color: @white;
      .transition(all 0.5s linear 0s);
    }
  }
  #contact-form {
    .form-group {
      margin: 0;
      input, textarea {
        border-radius: 0;
        background: #f6f6f6;
        border: none;
        margin-bottom: 20px;
        height: auto;
        .box-shadow(none);
      }
      label {
        font-weight: normal;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
    }
  }

  legend {
    padding: 15px 0;
  }
  .dl-horizontal {
    &.contact-position {
      dd {
        font-size: 20px;
        text-transform: uppercase;
        padding: 15px 0 0 15px;
      }
    }
    dt, dd {
      padding-bottom: 10px;
    }
    dd {
      margin: 0;
    }
    dt {
      width: auto;
      padding-right: 15px;
    }
  }
  .nav-tabs > li {
    &.open {
      a {
        border-color: #EEEEEE;
      }
    }
    > a {
      color: @color-4;
    }
  }
  .form-horizontal {
    .control-label {
      text-align: left;
    }
    .checkbox input[type=checkbox] {
      margin-left: 0;
    }
    .btn-primary {
      background: #334878;
      border-color: #334878;
      border-radius: 0;
      margin: 50px auto auto;
      text-transform: uppercase;
      padding: 12px 20px;
      i {
        padding-right: 15px;
      }
      &:hover {
        background: @white;
        border-color: #334878;
        color: #334878;
      }
    }
  }
}

/*----- New Feed ------*/
.newsfeed {
  > h2 {
    a {
      color: @color-4;
    }
  }
  ol {
    li {
      a {
        color: @color-4;
        &:hover {
          color: #334878;
        }
      }
      .feed-item-description {
        color: #bababa;
        ul {
          li {
            a {
              text-decoration: underline;
              color: #bababa;
            }
          }
        }
      }
    }
  }
}

.newsfeed-category {
  .list-striped {
    margin: 15px 0;
    border: none;
    li {
      border: none;
      background: transparent !important;
      padding: 0 0 15px 0;
      .list {
        float: none !important;
        display: block;
        a {
          color: @color-4;
          font-size: 20px;
          font-weight: normal;
        }
      }
    }
  }
  input {
    border-radius: 2px;
    padding: 5px 10px;
    border: 1px solid #EEEEEE;
  }
}

/*-------- Other --------*/
.tzautoplay {
  background: none repeat scroll 0 0 #5da7f0;
  border: 0 none;
  border-radius: 50%;
  display: inline-block;
  height: 62px;
  text-align: center;
  width: 62px;
  i {
    color: @white;
    font-size: 18px;
    line-height: 60px;
    .transition(all 0.3s linear 0s);
  }

}

.tz-background-color-6 {
  background: none repeat scroll 0 0 #930021 !important;
}

.tzpause {
  background: #5da7f0 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 50%;
  display: none;
  height: 62px;
  text-align: center;
  width: 62px;
  i {
    color: @white;
  }
}

.ds-table {
  display: table;
  width: 100%;
  height: 100%;
}

.ds-table-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.full-width {
  padding: 0;
}

.tz-more-details {
  display: inline-block;
  float: right;
  margin: 40px 0 0;
  overflow: hidden;
  position: relative;
  &:after {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    content: "";
    height: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    .transition(all 0.3s linear 0s);
    width: 100%;
  }
  span {
    border: 1px solid rgb(68, 68, 68);
    color: rgb(68, 68, 68);
    display: inline-block;
    line-height: 25.2px;
    padding: 10px 23px;
    position: relative;
    z-index: 99;
    i {
      display: inline-block;
      padding-right: 20px;
    }
  }
  &:hover {
    &:after {
      background: rgba(0, 0, 0, 1) none repeat scroll 0 0;
      right: 0;
    }
    span {
      color: @white;
    }
  }
}

.tz-btn-default {
  background: transparent;
  color: @color-4;
  border: 1px solid @color-4;
  padding: 10px 15px;
  border-radius: 0;
  .transition(all 0.3s linear 0s);
  text-transform: uppercase;
  i {
    padding-right: 15px;
  }
  &:hover {
    background: #334878;
    color: @white;
    border-color: #334878;
  }
}

.default {
  p {
    color: #999999;
    font-size: 14px;
    line-height: 25.2px;
    margin: 0;
    padding: 0 0 25px;
  }
}

.tz-slide,
.tz-video {
  background: @color-4;
  border-radius: 50%;
  height: 55px;
  left: 40%;
  padding: 15px 0;
  position: absolute;
  top: 40%;
  .transition(all 0.3s linear 0s);
  width: 55px;
  z-index: 2;
  text-align: center;
  i {
    font-size: 18px;
    color: @white;
    top: 2px;
    position: relative;
  }
}

input[type="text"], textarea, .form-control {
  &:focus {
    .box-shadow(0 0 2px 1px #334878);
  }
}

.loading-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: @white;
  z-index: 999999999;
}
