@media (max-width: 1199px) {
  .owl-controls {
    display: none;
  }

  .tz-institution {
    > * {
      display: table-cell !important;
    }
    p {
      padding: 0 15px;
    }
    strong {
      padding: 0;
    }
  }

  .tz-header-1 {
    #plazart-mainnav .level0 > li > a {
      border-width: 1px !important;
      padding: 20px 17px;
    }
    #tzlogo {
      border-width: 1px;
      padding: 12px 0 12px 15px;
    }
  }

  .joms-body .joms-stream__body {
    width: 84% !important;
    padding-left: 0;
  }

  .tz-social-login {
    .tz-input-pass, .tz-input-name {
      width: auto;
    }
    form button.joms-button--login {
      display: block;
      padding: 10px 30px;
      margin-top: 20px;
    }
  }

  .tz-filter-member ul.joms-list--alphabet {
    display: block;
    float: none;
    margin: auto;
  }

  .joms-single-group,
  .joms-single-member {
    .joms-focus__link {
      padding-left: 160px;
      li {
        padding: 5px 10px 5px 0;
        &:first-child {
          padding-left: 10px;
        }
        a {
          font-size: 12px;
        }
      }
    }
    .joms-focus__header {
      .joms-avatar--focus {
        padding-left: 0;
      }
    }
  }
}