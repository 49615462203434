@-webkit-keyframes icon-video {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes icon-video {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-o-keyframes icon-video {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes icon-video {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-o-keyframes rotate-icon {
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes scale-icon {
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes rotate-icon {
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes scale-icon {
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes rotate-icon {
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes scale-icon {
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes rotate-icon {
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes scale-icon {
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes rotate-icon {
  0% {
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scale-icon {
  0% {
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes bounceY {
  0% {
    -webkit-transform: translateY(-205px);
    -moz-transform: translateY(-205px);
    -o-transform: translateY(-205px);
    -ms-transform: translateY(-205px);
    transform: translateY(-205px);
  }
  40% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
  }
  65% {
    -webkit-transform: translateY(-52px);
    -moz-transform: translateY(-52px);
    -o-transform: translateY(-52px);
    -ms-transform: translateY(-52px);
    transform: translateY(-52px);
  }
  82% {
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  92% {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    -o-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  55%, 75%, 87%, 97%, 100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes bounceY {
  0% {
    -webkit-transform: translateY(-205px);
    -moz-transform: translateY(-205px);
    -o-transform: translateY(-205px);
    -ms-transform: translateY(-205px);
    transform: translateY(-205px);
  }
  40% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
  }
  65% {
    -webkit-transform: translateY(-52px);
    -moz-transform: translateY(-52px);
    -o-transform: translateY(-52px);
    -ms-transform: translateY(-52px);
    transform: translateY(-52px);
  }
  82% {
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  92% {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    -o-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  55%, 75%, 87%, 97%, 100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-ms-keyframes bounceY {
  0% {
    -webkit-transform: translateY(-205px);
    -moz-transform: translateY(-205px);
    -o-transform: translateY(-205px);
    -ms-transform: translateY(-205px);
    transform: translateY(-205px);
  }
  40% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
  }
  65% {
    -webkit-transform: translateY(-52px);
    -moz-transform: translateY(-52px);
    -o-transform: translateY(-52px);
    -ms-transform: translateY(-52px);
    transform: translateY(-52px);
  }
  82% {
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  92% {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    -o-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  55%, 75%, 87%, 97%, 100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-o-keyframes bounceY {
  0% {
    -webkit-transform: translateY(-205px);
    -moz-transform: translateY(-205px);
    -o-transform: translateY(-205px);
    -ms-transform: translateY(-205px);
    transform: translateY(-205px);
  }
  40% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
  }
  65% {
    -webkit-transform: translateY(-52px);
    -moz-transform: translateY(-52px);
    -o-transform: translateY(-52px);
    -ms-transform: translateY(-52px);
    transform: translateY(-52px);
  }
  82% {
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  92% {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    -o-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  55%, 75%, 87%, 97%, 100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes bounceY {
  0% {
    -webkit-transform: translateY(-205px);
    -moz-transform: translateY(-205px);
    -o-transform: translateY(-205px);
    -ms-transform: translateY(-205px);
    transform: translateY(-205px);
  }
  40% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
  }
  65% {
    -webkit-transform: translateY(-52px);
    -moz-transform: translateY(-52px);
    -o-transform: translateY(-52px);
    -ms-transform: translateY(-52px);
    transform: translateY(-52px);
  }
  82% {
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  92% {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    -o-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  55%, 75%, 87%, 97%, 100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}